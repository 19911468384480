<template>
  <b-jumbotron
    bg-variant="secondary"
    class="m-0 p-7 pb-0 welcome-banner"
  >
    <b-container class="text-center text-white bg-primary p-2 p-sm-4">
      <b-row
        align-v="center"
        class="text-white"
      >
        <b-col
          cols="12"
          md="8"
        >
          <h1 class="d-none d-md-block">
            {{ data.name }}
          </h1>
          <h2 class="d-block d-md-none">
            {{ data.name }}
          </h2>
          <template v-if="isInvited">
            <p>
              Gratulujeme! Na základe tvojho umiestnenia by sme ťa
              radi pozvali na sústredenie.
            </p>
            <p>
              Konať sa bude {{ eventDate }} na mieste: {{ data.site }}.
              Viac informácií nájdeš v prihláške.
            </p>
          </template>
          <template v-if="isAccepted">
            <p>
              Ďakujeme za vyplnenie prihlášky, tešíme sa na teba na sústredení.
            </p>
            <p>
              Podrobnosti a informácie o platbe nájdeš v prihláške.
            </p>
          </template>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-button
            variant="primary"
            class="text-white border m-3 p-3"
            :to="{ name: 'event-detail', params: { eventId: data.id } }"
          >
            <font-awesome-icon
              :icon="['fas', isInvited ? 'file-contract': 'check-circle']"
              size="4x"
            />
            <h5 class="m-0 mt-1">
              Otvoriť prihlášku
            </h5>
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </b-jumbotron>
</template>

<script>
import { utils } from 'frontend-common'
import constants from '@/constants'

export default {
  name: 'HomeWideBanner',
  components: {},
  mixins: [],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    eventDate() {
      return utils.datesToDuration(this.data.start_date, this.data.end_date)
    },
    isInvited() {
      return this.data.attendance_status === constants.attendance.status.INVITED
    },
    isAccepted() {
      return this.data.attendance_status === constants.attendance.status.ACCEPTED
    },
  },
  mounted() {},
  methods: {},
}
</script>

<style scoped>
</style>
