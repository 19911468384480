<template>
  <LoadingSpinner :is-loaded="isLoaded">
    <h2>Série, v ktorých si súťažil/-a:</h2>
    <b-row
      v-for="result in results"
      :key="result.id"
      align-v="center"
      class="p-2 py-3 m-3 wide-border border text-center"
    >
      <b-col
        cols="12"
        class="pb-2 pb-md-0"
      >
        <h3>{{ result.contest.name }} – {{ result.group }} – Kategória {{ result.name }}</h3>
      </b-col>
      <b-col
        sm="6"
      >
        <table>
          <tr>
            <td v-b-popover.hover.top.window="'úloha'">
              ú
            </td>
            <td
              v-for="header of problemHeaders(result)"
              :key="header.key"
            >
              {{ header.label }}
            </td>
          </tr>

          <tr>
            <td v-b-popover.hover.top.window="'body'">
              b
            </td>
            <td
              v-for="points of problemPoints(result)"
              :key="points.key"
            >
              <div
                v-if="points.value"
                :class="points.counted ? 'font-weight-bold' : 'text-muted'"
              >
                {{ points.value || '-' }}
              </div>
            </td>
          </tr>
        </table>
      </b-col>
      <b-col
        sm="6"
      >
        <ResultsRowSum
          :row="result.row"
          previous
        />
        <b-row
          align-h="center"
          class="mt-2"
        >
          <b-col cols="auto">
            <b-button
              variant="outline-primary"
              :to="{name: 'round', params: {roundId: result.properties.round_id}}"
            >
              Úlohy
            </b-button>
          </b-col>
          <b-col cols="auto">
            <b-button
              variant="outline-primary"
              :to="{name: 'results', params: {roundId: result.properties.round_id}}"
            >
              Výsledky
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </LoadingSpinner>
</template>

<script>
import apiResults from '../api/results'
import get from 'lodash.get'
import LoadingSpinner from './utils/LoadingSpinner.vue'
import ResultsRowSum from './utils/ResultsRowSum.vue'

export default {
  name: 'PersonalResultHistory',
  components: {
    LoadingSpinner,
    ResultsRowSum,
  },
  mixins: [
    apiResults,

  ],
  props: {},
  data() {
    return {
      isLoaded: false,
      results: [],
    }
  },
  computed: {
  },
  mounted() {
    this.apiResultsPersonal().
      then(response => this.results = response.reverse()).
      finally(() => this.isLoaded = true)
  },
  methods: {
    problemHeaders(result) {
      return result.header.filter(header => header.type === 'problem')
    },
    problemPoints(result) {
      return result.header.filter(header => header.type === 'problem').map(header =>
        ({
          value: get(result.row, header.key, null),
          counted: get(result.row, header.counted, false),
          key: header.key,
        }))
    },
  },
}
</script>

<style scoped>
.wide-border {
  border-width: 2px !important;
}

table {
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
  border-style: hidden;
  table-layout: fixed;
}

table td {
  vertical-align: text-top;
  width: 45px;
  border: 1px solid var(--secondary);
}
</style>
