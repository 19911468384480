<template>
  <b-jumbotron
    bg-variant="secondary"
    class="m-0 p-7 pb-0 welcome-banner"
  >
    <b-container class="text-center text-white bg-primary p-2 p-sm-4">
      <b-row
        align-v="center"
        class="text-white"
      >
        <b-col
          cols="12"
          md="2"
        >
          <font-awesome-icon
            :icon="['fas', 'exclamation']"
            size="6x"
            class="exclamation-mark"
          />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <h1 class="d-none d-md-block">
            Skontroluj si svoje údaje.
          </h1>
          <p>
            Ahoj! Začal sa nový školský rok, a preto by sme ťa poprosili, aby si si skontroloval,
            či sú údaje v tvojom profile stále aktuálne. Skontroluj si hlavne <b>kategóriu</b> a
            <b>školu</b>, ak sa ti zmenila.
          </p>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-button
            variant="primary"
            class="text-white border m-3 p-3"
            :to="{ name: 'profile-change' }"
          >
            <font-awesome-icon
              :icon="['fas', 'user-cog']"
              size="4x"
            />
            <h5 class="m-0 mt-1">
              Skontrolovať profil
            </h5>
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </b-jumbotron>
</template>

<script>
export default {
  name: 'HomeProfileUpdateBanner',
}
</script>

<style>
.exclamation-mark {
  color: var(--warning);
  transform: rotate(-10deg);
}
</style>
