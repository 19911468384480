<template>
  <FormSelect
    :context="context"
    :current-options="schools"
    :search="getSchools"
    special-prefix="Iná škola"
  />
</template>

<script>
import apiUsers from '../../../api/users'
import FormSelect from './FormSelect.vue'

export default {
  name: 'FormSelectSchool',
  components: {
    FormSelect,
  },
  mixins: [
    apiUsers,
  ],
  props: {
    context: {
      type: Object,
      required: true,
    },
    initialSchools: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      schools: [],
    }
  },
  computed: {},
  mounted() {
    if (this.context.initialSchools)
      this.schools = this.context.initialSchools
  },
  methods: {
    getSchools(query) {
      return this.apiSchools(query).then(response => {
        const schools = response.results.
          map(school => ({label: `${school.name}, ${school.city}`, value: school.id}))
        if (response.tooManyResults)
          schools.push({
            label: 'Tvojmu vyhľadávaniu vyhovuje priveľa škôl, skús ho upresniť.',
            value: null,
            disabled: true,
          })
        this.schools = schools
      })
    },
  },
}
</script>

<style scoped>
</style>
