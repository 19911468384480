import { constants } from 'frontend-common'

export default {
  ...constants,

  // Start of build-time determined values (they use process.env)
  // This should be the only chunk of code using process.env

  // End of build-time determined values
  umamiKey: {
    'beta.pikofyz.sk': '128e5622-ebfd-4075-83ec-a11c7c756794',
    'beta.pikomat.sk': '1afbb593-3b3a-41b5-a5ec-8f456a5de7fd',
    'beta.terabio.sk': '398f2c17-8a29-4d55-bf24-0a3db91597b3',
    'pikofyz.sk': '16718fa6-726b-41ff-b407-c50a97c3996f',
    'pikomat.sk': 'd57cc5dc-a617-48e9-b161-4d5f8f429c9d',
    'terabio.sk': '39f396b3-dec2-4f7e-b89b-11ec2c0135d0',
  },

  googleMapsKey: 'AIzaSyCQJmmGS78-tSl9up8PZZ002EjXqV6jAkg',
}
