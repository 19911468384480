import {
  api, bootstrapPlugins, formulateSetup, iconList, injectExponea, modals, storage, toasts,
} from 'frontend-common'
import constants from '@/constants'
import Vue from 'vue'

// Seminary selection and Bootstrap theme loading
let seminary = {}
if (constants.frontendDevTheme) {
  import('frontend-common/themes/dev.scss')
}
switch (constants.frontendTheme) {
case 'beta.terabio':
  import('frontend-common/themes/beta.scss')
  import('@/themes/terabio.scss')
  seminary = { ...constants.terabioSeminary }
  break
case 'beta.pikofyz':
  import('frontend-common/themes/beta.scss')
  import('@/themes/pikofyz.scss')
  seminary = { ...constants.pikofyzSeminary }
  break
case 'beta.pikomat':
  import('frontend-common/themes/beta.scss')
  import('@/themes/pikomat.scss')
  seminary = { ...constants.pikomatSeminary }
  break
case 'terabio':
  import('@/themes/terabio.scss')
  seminary = constants.terabioSeminary
  break
case 'pikofyz':
  import('@/themes/pikofyz.scss')
  seminary = constants.pikofyzSeminary
  break
case 'pikomat':
default:
  import('@/themes/pikomat.scss')
  seminary = constants.pikomatSeminary
}

// Add `beta.` to the start of every url in beta themes
if (constants.frontendTheme.startsWith('beta.')) {
  for (const s of constants.allSeminary) {
    s.url.replace('https://', 'https://beta.')
  }
}


// BootstrapVue
for (const plugin of bootstrapPlugins)
  Vue.use(plugin)


// VueFormulate
import VueFormulate from '@braid/vue-formulate'

Vue.use(VueFormulate, formulateSetup)


// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
library.add(...iconList)

Vue.component('font-awesome-icon', FontAwesomeIcon)


// VueScrollTo
import VueScrollTo from 'vue-scrollto'

Vue.use(VueScrollTo)


// Google Auth
Vue.use((vue) => {
  let resolvers = []
  let rejecters = []

  // Google SDK is loaded with script tag in `public/index.html`
  // eslint-disable-next-line no-undef
  const client = google.accounts.oauth2.initCodeClient({
    client_id: constants.googleClientId,
    scope: 'profile email',
    callback: (response) => {
      if (response.code) {
        resolvers.forEach((resolve) => resolve(response.code))
        resolvers = []
        rejecters = []
      } else {
        rejecters.forEach((reject) => reject(response))
        resolvers = []
        rejecters = []
      }
    },
  })

  Object.defineProperties(vue.prototype, {
    $googleClient: {
      get: () => {
        // eslint-disable-next-line no-undef
        return {
          loginPopup: () => new Promise((resolve, reject) => {
            client.requestCode()
            resolvers.push(resolve)
            rejecters.push(reject)
          }),
        }
      },
    },
  })
})

// Moment JS i18n
import 'moment/locale/sk'
import moment from 'moment'

moment.updateLocale('sk', { relativeTime: { future: 'o %s' } })


// KaTeX
import '../node_modules/katex/dist/katex.min.css'


// Umami
import inject_umami from '@/umami'

const umamiKey = constants.umamiKey[window.location.hostname] || null
inject_umami(umamiKey)


// Exponea
const exponea = injectExponea()


// Sentry error reporting
import * as Sentry from '@sentry/browser'
import {Vue as VueIntegration} from '@sentry/integrations'

if (constants.enableSentry) {
  Sentry.init({
    dsn: constants.sentryDsn,
    integrations: [
      // This is a temporary fix to prevent stack overflow, see:
      // https://github.com/getsentry/sentry-javascript/issues/2957#issuecomment-713505111
      new Sentry.Integrations.TryCatch({ eventTarget: false }),
      new VueIntegration({ Vue, attachProps: true }),
    ],
    environment: constants.sentryEnv,
  })
}

import router from '@/router'

// Vue
import App from '@/App.vue'

Vue.config.productionTip = false

new Vue({
  router,
  mixins: [
    api,
    exponea,
    modals,
    storage,
    toasts,
  ],
  data() {
    return {
      seminary,
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        const page = to.meta.title ? `${to.meta.title} · ` : ''
        document.title = page + seminary.title
      },
    },
  },
  render: h => h(App),
}).$mount('#app')
