import { Page, PasswordResetConfirm, ProfileChange, ProfileDetail } from 'frontend-common'
import constants from '@/constants'
import Home from '@/views/Home.vue'
import MainNav from '@/views/MainNav.vue'
import Router from 'vue-router'
import Vue from 'vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  scrollBehavior (to, from) {
    if (to.path === from.path) {
      return
    }
    return {x: 0, y: 0}
  },
  base: constants.vueBaseUrl,
  routes: [
    {
      path: '/print-view/:roundId',
      name: 'print-view',
      component: () => import (/* webpackChunkName: "print-view" */ './views/PrintView.vue'),
    },
    {
      path: '/interactive/:problemId',
      name: 'interactive',
      component: () => import (/* webpackChunkName: "interactive" */ './views/Interactive.vue'),
    },
    {
      path: '/',
      component: MainNav,
      children: [
        {
          path: '',
          name: 'home',
          component: Home,
          meta: { title: 'Domov' },
        },
        {
          path: 'ulohy/:roundId?',
          name: 'round',
          component: () => import(/* webpackChunkName: "contests" */ '@/views/Contests.vue'),
          props: route => ({ roundId: Number(route.params.roundId) || null }),
          meta: { title: 'Zadania' },
        },
        {
          path: 'vysledky/:roundId?',
          name: 'results',
          component: () => import(/* webpackChunkName: "results" */ '@/views/Results.vue'),
          props: route => ({ roundId: Number(route.params.roundId) || null }),
          meta: { title: 'Výsledky' },
        },
        {
          path: 'sustredenia/',
          name: 'events',
          component: () => import(/* webpackChunkName: "events" */ '@/views/Events.vue'),
          meta: { title: 'Sústredenia' },
        },
        {
          path: 'sustredenia/:eventId',
          name: 'event-detail',
          component: () => import(/* webpackChunkName: "event-detail" */ '@/views/EventDetail.vue'),
          props: route => ({ eventId: Number(route.params.eventId) || null }),
          meta: { title: 'Sústredenie' },
        },
        {
          path: 'profil',
          name: 'profile-detail',
          component: ProfileDetail,
          meta: { title: 'Môj profil' },
        },
        {
          path: 'profil/zmena',
          name: 'profile-change',
          component: ProfileChange,
          meta: { title: 'Zmena profilu' },
        },
        {
          path: 'overenie-emailu/:token?',
          name: 'email-verification',
          component: () => import(
            /* webpackChunkName: "email-verification" */ './views/EmailVerification.vue'
          ),
          props: route => ({
            token: route.params.token,
            email: route.query.email,
          }),
          meta: { title: 'Overenie emailu' },
        },
        {
          path: 'obnova-hesla/:uid/:token',
          name: 'password-reset-confirm',
          component: PasswordResetConfirm,
          props: route => ({ uid: route.params.uid, token: route.params.token }),
          meta: { title: 'Obnova hesla' },
        },
        {
          path: 'kalendar',
          name: 'kk-events',
          component: () => import (/* webpackChunkName: "kk-events" */ './views/Calendar.vue'),
          meta: { title: 'Naše akcie' },
        },
        {
          path: 's/:pageSlug',
          name: 'page',
          component: Page,
          props: route => ({ pageSlug: route.params.pageSlug }),
        },
        {
          path: ':pageSlug',
          redirect: { name: 'page' },
        },
        {
          path: '*',
          name: 'not-found',
          component: Page,
          props: () => ({ pageSlug: '' }),
        },
      ],
    },
  ],
})
