<template>
  <div :class="`ribbon ribbon-${placement}`">
    <span><font-awesome-icon :icon="['fas', `${icon}`]" /></span>
  </div>
</template>

<script>
export default {
  name: 'CornerIconRibbon',
  components: {},
  mixins: [],
  props: {
    placement: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
      default: 'star',
    },
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {},
}
</script>


<style scoped>
  /* common */
  .ribbon {
    width: 75px;
    height: 75px;
    overflow: hidden;
    position: absolute;
  }
  .ribbon::before,
  .ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid var(--blue);
  }
  .ribbon span {
    position: absolute;
    display: block;
    width: 106px;  /* sqrt(ribbon.width^2 + ribbon.height^2) */
    padding: 7px 0;
    background-color: var(--pmat-blue);
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: #fff;
    font-weight: bold;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-align: center;

  }

  /* top left*/
  .ribbon-top-left {
    top: -8px;
    left: -8px;
  }
  .ribbon-top-left::before,
  .ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
  }
  .ribbon-top-left::before {
    top: 0;
    right: 0;
  }
  .ribbon-top-left::after {
    bottom: 0;
    left: 0;
  }
  .ribbon-top-left span {
    right: -4px;
    top: 10px;
    transform: rotate(-45deg);
  }

  /* top right*/
  .ribbon-top-right {
    top: -8px;
    right: -8px;
  }
  .ribbon-top-right::before,
  .ribbon-top-right::after {
    border-top-color: transparent;
    border-right-color: transparent;
  }
  .ribbon-top-right::before {
    top: 0;
    left: 0;
  }
  .ribbon-top-right::after {
    bottom: 0;
    right: 0;
  }
  .ribbon-top-right span {
    left: -4px;
    top: 10px;
    transform: rotate(45deg);
  }

  /* bottom left*/
  .ribbon-bottom-left {
    bottom: -8px;
    left: -8px;
  }
  .ribbon-bottom-left::before,
  .ribbon-bottom-left::after {
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  .ribbon-bottom-left::before {
    bottom: 0;
    right: 0;
  }
  .ribbon-bottom-left::after {
    top: 0;
    left: 0;
  }
  .ribbon-bottom-left span {
    right: -4px;
    bottom: 10px;
    transform: rotate(45deg);
  }

  /* bottom right*/
  .ribbon-bottom-right {
    bottom: -8px;
    right: -8px;
  }
  .ribbon-bottom-right::before,
  .ribbon-bottom-right::after {
    border-bottom-color: transparent;
    border-right-color: transparent;
  }
  .ribbon-bottom-right::before {
    bottom: 0;
    left: 0;
  }
  .ribbon-bottom-right::after {
    top: 0;
    right: 0;
  }
  .ribbon-bottom-right span {
    left: -4px;
    bottom: 10px;
    transform: rotate(-45deg);
  }

</style>
