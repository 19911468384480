const constants = {

  // Start of build-time determined values (they use process.env)
  // This should be the only chunk of code using process.env
  googleClientId: process.env.NODE_ENV === 'production' ?
    process.env.VUE_APP_GOOGLE_CLIENT_ID :
    '950644876351-oah6cbv0s6fabqkv1mof1gc4pmg47ccm.apps.googleusercontent.com',
  exponeaToken: process.env.VUE_APP_EXPONEA_TOKEN || '54ab872a-8653-11eb-a289-56370d4f6901',
  enableSentry: process.env.VUE_APP_ENABLE_SENTRY === '1',
  sentryDsn: process.env.VUE_APP_SENTRY_DSN || null,
  sentryEnv: process.env.VUE_APP_SENTRY_ENV || 'devel',
  recaptchaSitekey: process.env.NODE_ENV === 'production' ?
    process.env.VUE_APP_RECAPTCHA_SITE_KEY :
    '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI', // Official google testing key

  backendUrl: process.env.VUE_APP_BACKEND_URL ||
    `${window.location.protocol}//${window.location.hostname}:` +
    `${process.env.VUE_APP_BACKEND_PORT || 8000}`,

  backendApiNamespace: `v${process.env.VUE_APP_API_VERSION}`,

  axiosIgnoreCache: process.env.NODE_ENV !== 'production',
  apiIgnoreSites: process.env.VUE_APP_API_IGNORE_SITES === '1',
  vueBaseUrl: process.env.BASE_URL,

  frontendDevTheme: process.env.VUE_APP_DEV_THEME !== '0',
  frontendTheme: process.env.VUE_APP_FRONTEND_THEME,
  // End of build-time determined values

  httpMethods: {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    PATCH: 'patch',
    DELETE: 'delete',
    HEAD: 'head',
  },
  apiIgnoreSitesHeader: 'PMAT-Ignore-Sites',
  mimeTypes: {
    PDF: 'application/pdf',
    ZIP: 'application/zip',
    JPG: 'image/jpeg',
    PNG: 'image/png',
  },
  acceptedSubmitFormats: [
    'application/pdf',
    'image/jpeg',
    'image/png',
  ],
  acceptedSolutionFormats: [
    'application/pdf',
  ],
  maxAgeDefaults: { // in milliseconds
    // TODO cache is temporarily set to very low values in order to make testing easier.
    short:   3 * 1000,
    mid:     3 * 1000,
    long:    3 * 1000,
  },
  gdprMessages: {
    firstName: 'Tento údaj zbierame preto, že v Pikomate a Pikofyze poskytujeme individuálnu ' +
      'spätnú väzbu na vyriešené úlohy. Tento údaj je súčasťou výsledkovej listiny.',
    lastName: 'Tento údaj zbierame preto, že v Pikomate a Pikofyze poskytujeme individuálnu ' +
      'spätnú väzbu na vyriešené úlohy. Tento údaj je súčasťou výsledkovej listiny.',
    school: 'Tento údaj zbierame preto, lebo je súčasťou výsledkovej listiny.',
    category: 'Tento údaj zbierame preto, aby sme ťa vedeli zaradiť do správnej kategórie.',
    sex: 'Tento údaj zbierame preto, aby sme mohli s tebou komunikovať v správnom rode.',
    phone: 'Tento údaj nám pomôže v rýchlejšej komunikácii s tebou, ak by vznikli nejaké ' +
      'neštandardné situácie.',
    parent: 'Tieto údaje nám pomôžu v prípade, že sa nebudeme vedieť s tebou spojiť.',
    address: 'Tento údaj zbierame preto, aby sme vedeli, kam ti zaslať cenu v prípade tvojho ' +
      'úspechu v súťaži. Tento údaj zbierame aj z dôvodu zmluvnej povinnosti vyplývajúcej z ' +
      'projektu MŠVVaŠ SR, ktorým spolufinancujeme Pikomat/Pikofyz.',
    birthNumber: 'Tento údaj zbierame z dôvodu zmluvnej povinnosti vyplývajúcej z projektu ' +
      'MŠVVaŠ SR, ktorým spolufinancujeme Pikomat/Pikofyz. Overiť si to je možné napríklad v ' +
      '<a target="_blank" href="https://www.minedu.sk/data/att/16215.pdf">tabuľke schválených ' +
      'žiadostí o NFP</a>. Tvoje rodné číslo na iné účely <em>nepoužívame</em>.',
    reference: 'Tento údaj zbierame na štatistické účely, aby sme vedeli prispôsobiť našu ' +
      'komunikáciu tak, aby bola efektívnejšia.',
    email: 'Tvoju emailovú adresu zbierame, aby si sa pomocou nej mohol/-la prihlasovať. ' +
      'Ukladáme si ju tiež na to, aby sme s tebou mohli komunikovať o tvojich riešeniach, ' +
      'nových zadaniach a prípadne pozvánkach na nadväzujúce akcie – ako napr. sústredenia.',
    agreement: 'Súhlasím so spracovaním mojich osobných údajov podľa podmienok uvedených vo ' +
               '<a target="_blank" href="/gdpr">vyhlásení o spracovaní osobných údajov</a>.',
    eventFirstName: 'Tento údaj zbierame kvôli zoznamu účastníkov na pobytových akciách a ' +
      'evidujeme po dobu 10 rokov, pretože veľké % našich dobrovoľníkov sú bývalí účastníci z ' +
      'pobytových akcií.',
    eventLastName: 'Tento údaj zbierame kvôli zoznamu účastníkov na pobytových akciách a ' +
      'evidujeme po dobu 10 rokov, pretože veľké % našich dobrovoľníkov sú bývalí účastníci z ' +
      'pobytových akcií.',
    eventEmail: 'Tento údaj zbierame kvôli komunikácii s účastníkom a evidujeme po dobu 10 ' +
      'rokov, pretože veľké % našich dobrovoľníkov sú bývalí účastníci z pobytových akcií.',
    eventAddress: 'Tento údaj zbierame z dôvodu zmluvnej povinnosti vyplývajúcej z projektu ' +
      'MŠVVaŠ SR, ktorým spolufinancujeme Pikomat/Pikofyz.',
    eventSchool: 'Tento údaj zbierame kvôli zoznamu účastníkov na sústredení a možnosti robiť ' +
      'adresnejšie zameraný program na pobytových akciách.',
    eventCategory: 'Tento údaj zbierame kvôli zoznamu účastníkov na sústredení a možnosti robiť ' +
      'adresnejšie zameraný program na pobytových akciách.',
    eventPhone: 'Tento údaj zbierame kvôli komunikácii s účastníkom.',
    eventHealth: 'Tieto údaje zbierame, aby sme vedeli správne reagovať pri vzniknutých ' +
      'zdravotných komplikáciách na sústredení. Zároveň tieto údaje nám umožňujú prispôsobiť ' +
      'program tak, aby sme predchádzali možným zdravotným rizikám.',
    eventTransport: 'Tento údaj zbierame, aby sme vedeli logisticky zabezpečiť sústredenie.',
    eventParentDetails: 'Tento údaj zbierame, aby sme mohli komunikovať s rodičmi ' +
      'pred/počas aj po pobytovej akcii.',
    eventPrice: 'Tento údaj zbierame v rámci účtovníctva v zmysle platnej legislatívy.',
  },
  pikomatSeminary: {
    title: 'Pikomat',
    titleCases: {
      genitive: 'Pikomatu',
    },
    logo: '/logos/pikomat.svg',
    url: 'https://pikomat.sk',
    variant: 'pmat-blue',
    slides: [
      {
        desktop: '/slider/pm.webp',
        mobile: '/slider/pm-mobile.webp',
        route: { name: 'page', params: { pageSlug: 'seminar' }},
      },
      {
        desktop: '/slider/1.webp',
        mobile: '/slider/1-mobile.webp',
        route: { name: 'page', params: { pageSlug: 'sustredenie' }},
      },
      {
        desktop: '/slider/2.webp',
        mobile: '/slider/2-mobile.webp',
        route: { name: 'page', params: { pageSlug: 'o-nas' }},
      },
    ],
  },
  pikofyzSeminary: {
    title: 'Pikofyz',
    titleCases: {
      genitive: 'Pikofyzu',
    },
    logo: '/logos/pikofyz.svg',
    url: 'https://pikofyz.sk',
    variant: 'pmat-orange',
    slides: [
      {
        desktop: '/slider/pf.webp',
        mobile: '/slider/pf-mobile.webp',
        route: { name: 'page', params: { pageSlug: 'seminar' }},
      },
      {
        desktop: '/slider/1.webp',
        mobile: '/slider/1-mobile.webp',
        route: { name: 'page', params: { pageSlug: 'sustredenie' }},
      },
      {
        desktop: '/slider/2.webp',
        mobile: '/slider/2-mobile.webp',
        route: { name: 'page', params: { pageSlug: 'o-nas' }},
      },
    ],
  },
  terabioSeminary: {
    title: 'Terabio',
    titleCases: {
      genitive: 'Terabia',
    },
    logo: '/logos/terabio.svg',
    url: 'https://terabio.sk',
    variant: 'pmat-green',
    slides: [
      {
        desktop: '/slider/tb.webp',
        mobile: '/slider/tb-mobile.webp',
        route: { name: 'page', params: { pageSlug: 'seminar' }},
      },
      {
        desktop: '/slider/1.webp',
        mobile: '/slider/1-mobile.webp',
        route: { name: 'page', params: { pageSlug: 'sustredenie' }},
      },
      {
        desktop: '/slider/2.webp',
        mobile: '/slider/2-mobile.webp',
        route: { name: 'page', params: { pageSlug: 'o-nas' }},
      },
    ],
  },
  forms: {
    customPrice: {label: 'Vlastná cena', value: 'other'},
    discountRequest: {
      label: 'Požiadať o príspevok z fondu na podporu riešiteľov.', value: 'discount',
    },
  },
  attendance: {
    status: {
      INVITED: 0,
      ACCEPTED: 1,
      DECLINED: 2,
      CANCELLED: 3,
    },
    invitation: {
      NONE: 0,
      NORMAL: 1,
      SUBSTITUTE: 2,
      ORGANIZER: 3,
    },
  },
  pictureSizeLimits: { // in kB, used mostly for showing warnings to organizers
    large: 400,
    tooLarge: 700,
  },
  autoReloadIntervals: {
    short: 10 * 1000,
    mid:   60 * 1000,
    long:  600 * 1000,
  },
  // How much ms to wait before reloading status, used as a hotfix in cases where
  // we want to avoid a race condition, but cannot do it precisely.
  statusLoadDelay: 3000,
  contestGroups: {
    ALL: 'all',
    PIKOMAT: 'Pikomat',
    PIKOFYZ: 'Pikofyz',
    TERABIO: 'Terabio',
  },
}

constants.allSeminary = [
  constants.pikomatSeminary,
  constants.pikofyzSeminary,
  constants.terabioSeminary,
]

for (const seminary of constants.allSeminary) {
  seminary.otherSeminary = constants.allSeminary.filter(s => s !== seminary)
}

export default constants
