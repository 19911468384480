<template>
  <FormulateForm
    v-model="formData"
    name="registration-step-one"
    :schema="formSchema"
    @submit="nextStep(); trackRegisterNextStep()"
  >
    <b-button
      block
      size="lg"
      type="submit"
      variant="primary"
      @click.prevent="$formulate.submit('registration-step-one')"
    >
      <font-awesome-icon :icon="['fas', 'arrow-right']" />
      Pokračovať na druhý krok
    </b-button>
  </FormulateForm>
</template>

<script>
import analytics from '../../mixins/analytics'
import apiContests from '../../api/contests'
import apiUsers from '../../api/users'
import constants from '../../constants'
import FormHelpText from './utils/FormHelpText.vue'
import schema from './schema'

const {
  birthNumber, category, city, country, email, firstName, group, lastName, maskedIdentity, phone,
  postalNumber, req, row, school, seminaryReference, sex, street, streetNumber, terms, visualGroup,
  applyBlurHandlers,
} = schema

export default {
  name: 'RegisterStepOne',
  components: {},
  mixins: [
    apiUsers,
    apiContests,
    analytics,
  ],
  props: {},
  data() {
    return {
      formSchema: applyBlurHandlers(this, [
        row([
          visualGroup('Údaje riešiteľa', [
            row(req(firstName), req(lastName)),
            row(req(school), req(category)),
            row(req(sex), phone),
          ]),
          visualGroup('Údaje rodiča', [group('parent', [
            row({...firstName, tooltip: null}, {...lastName, tooltip: null}),
            row({...email, tooltip: null}, {...phone, tooltip: null}),
          ])], constants.gdprMessages.parent),
        ], [
          visualGroup('Adresa', [group('address', [
            row(req(street), req(streetNumber)),
            req(city),
            row(req(postalNumber), req(country)),
          ])], constants.gdprMessages.address),
          req(birthNumber),
          {
            component: FormHelpText,
            class: 'small mb-3',
            content: constants.gdprMessages.birthNumber,
          },
          seminaryReference,
          maskedIdentity,
          terms,
        ]),
      ]),
      formData: {},
    }
  },
  computed: {},
  mounted() {},
  methods: {
    nextStep() {
      this.formData.parent = this.formData.parent || [{}]
      const data = {
        ...this.formData,
        sex: this.formData.sex.value,
        competitor: {
          school: this.formData.school.value,
          category: this.formData.category.value,
          parent_first_name: this.formData.parent[0].first_name || '',
          parent_last_name: this.formData.parent[0].last_name || '',
          parent_email: this.formData.parent[0].email || '',
          parent_phone: this.formData.parent[0].phone || '',
        },
        sensitive: {
          address: {...this.formData.address[0], country: this.formData.address[0].country.value},
          phone: this.formData.phone || '',
          birth_number: this.formData.birth_number,
        },
        masked_identity: this.formData.masked_identity || false,
      }
      this.$emit('nextStep', data)
    },
  },
}
</script>

<style scoped>
</style>
