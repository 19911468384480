import { render, staticRenderFns } from "./LoginModal.vue?vue&type=template&id=a2609912&scoped=true&"
import script from "./LoginModal.vue?vue&type=script&lang=js&"
export * from "./LoginModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2609912",
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BTab, BButton, BTabs, BCard, BModal} from 'bootstrap-vue'
    installComponents(component, {BTab, BButton, BTabs, BCard, BModal})
    

export default component.exports