<template>
  <FormulateForm
    v-model="formData"
    name="register-organizer"
    :schema="formSchema"
    @submit-raw="$refs.googleRecaptcha.reset()"
    @submit="registerGoogle"
  >
    <b-row class="mx-3">
      <b-col
        offset-sm="2"
        sm="8"
        offset-md="3"
        md="6"
      >
        <vue-recaptcha
          ref="googleRecaptcha"
          class="mt-3"
          :load-recaptcha-script="true"
          :sitekey="recaptchaSitekey"
          @verify="recaptchaResponse = $event; $formulate.submit('register-organizer')"
        >
          <GoogleButton
            :is-loaded="!ongoingRegistration"
            :disabled="ongoingRegistration"
            label="Zaregistrovať sa pomocou P-MAT účtu"
          />
        </vue-recaptcha>
      </b-col>
    </b-row>
  </FormulateForm>
</template>

<script>
import apiUsers from '../../api/users'
import constants from '../../constants'
import FormHelpText from './utils/FormHelpText.vue'
import GoogleButton from './utils/GoogleButton.vue'
import schema from './schema'
import VueRecaptcha from 'vue-recaptcha'

const {
  birthNumber, city, country, firstName, group, lastName, phone, postalNumber, req, row, sex,
  street, streetNumber, terms, visualGroup, applyBlurHandlers,
} = schema

export default {
  name: 'RegisterOrganizerForm',
  components: {
    GoogleButton,
    VueRecaptcha,
  },
  mixins: [
    apiUsers,
  ],
  props: {},
  data() {
    return {
      formSchema: applyBlurHandlers(this, [
        row([{component: 'p', children: 'Vitaj milý vedúci/-a :)'},
             {component: 'p', children: 'Tu sa môžeš zaregistrovať na našich stránkach. ' +
               'Po tom ako vyplníš polia a klikneš na zaregistrovať, je nutné zvoliť ' +
               'tvoj @p-mat.sk účet.'},
             {component: 'p', children: 'Ak by si mal/-a nejaké ťažkosti, napíš nám na Slacku.'}]),
        row([
          visualGroup('Údaje organizátora', [
            row(req(firstName), req(lastName)),
            row(req(sex), phone),
            req(birthNumber),
            {
              component: FormHelpText,
              class: 'small mb-3',
              content: constants.gdprMessages.birthNumber,
            },
          ]),
        ], [
          visualGroup('Adresa', [group('address', [
            row(req(street), req(streetNumber)),
            req(city),
            row(req(postalNumber), req(country)),
          ])], constants.gdprMessages.address),
        ]),
        terms,
      ]),
      formData: {},
      ongoingRegistration: false,
      recaptchaSitekey: constants.recaptchaSitekey,
      recaptchaResponse: '',
    }
  },
  computed: {},
  mounted() {},
  methods: {
    registerGoogle() {
      const address = this.formData.address[0]
      const data = {
        ...this.formData,
        sex: this.formData.sex.value,
        sensitive: {
          address: {...address, country: address.country.value},
          phone: this.formData.phone || '',
          birth_number: this.formData.birth_number,
        },
        organizer: {role: ''},
        recaptcha: this.recaptchaResponse,
      }
      return this.$googleClient.loginPopup().
        then(auth_code => this.apiRegisterOrganizer(auth_code, data)).
        then(response => {
          this.$root.setAuthToken(response.key)
          this.$root.successToast('Registrácia vedúceho úspešná!')
          this.$emit('login-modal-close')
          this.$router.push({ name: 'home' })
        }).catch(e => {
          if (!e.response) {
            return this.$root.dangerToast(
              'Chyba počas voľby účtu Google. Skús prihlasovanie zopakovať. ' +
                'Ak by chyba pretrvávala, napíš nám na it@p-mat.sk.',
              8000,
            )
          }
          this.$root.dangerToast(e.response.data[0])
        })
    },
  },
}
</script>

<style scoped>

</style>
