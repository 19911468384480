<template>
  <article>
    <h1>{{ page.title }}</h1>
    <AdvancedMarkdown
      :source="page.content"
      class="my-4"
    />
    <p class="text-muted">
      Posledná úprava: {{ pageDate }}
    </p>
  </article>
</template>

<script>
import AdvancedMarkdown from './AdvancedMarkdown.vue'
import utils from '../utils'

export default {
  name: 'PageDetail',
  components: {
    AdvancedMarkdown,
  },
  mixins: [],
  props: {
    page: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    pageDate() {
      return utils.dateToString(this.page.date_modified)
    },
  },
  mounted() {},
  methods: {},
}
</script>

<style scoped>

</style>
