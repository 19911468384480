<template>
  <div>
    <image-gallery
      ref="gallery"
      :images="imageList"
      @close="currentImageIndex = -1"
    />
    <vue-markdown
      ref="markdown"
      class="markdown-container"
      :source="source"
      @rendered="rendered()"
    />
  </div>
</template>

<script>
import ImageGallery from './ImageGallery.vue'
import VueMarkdown from 'vue-markdown'
export default {
  name: 'AdvancedMarkdown',
  components: {
    VueMarkdown,
    ImageGallery,
  },
  props: {
    docId: {
      type: String,
      default: '',
    },
    source: {
      type: String,
      required: true,
    },
    openDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      imageList: [],
      currentImageIndex: -1,
    }
  },
  mounted() {
    this.rendered()
  },
  methods: {
    rendered() {
      // Return if the ref wasn't populated yet
      // This can happen on the first render,
      // refs are populated after child components are rendered
      if (!this.$refs.markdown) return

      // Open details if necesary
      if (this.openDetails)
        this.$refs.markdown.$el.querySelectorAll('details').
          forEach(el => el.open = true)

      this.setupImages()
      this.setupTables()
      this.fixFootnoteRefs()
    },
    fixFootnoteRefs() {
      // Tag all of the footnote references with additional `docId`
      // to avoid #id conflicts when rendering multiple markdown components
      // on one page
      if (!this.docId) return

      const elem = this.$refs.markdown.$el

      elem.querySelectorAll('li[id^="fn"]').forEach(el => {
        el.id = `${this.docId}-${el.id}`
      })
      elem.querySelectorAll('a[href^="#fnref"]').forEach(el => {
        el.href = `#${this.docId}-${el.getAttribute('href').slice(1)}`
      })
      elem.querySelectorAll('a[href^="#fn"]').forEach(el => {
        el.id = `${this.docId}-${el.id}`
        el.href = `#${this.docId}-${el.getAttribute('href').slice(1)}`
      })
    },
    setupTables() {
      const elem = this.$refs.markdown.$el
      const tables = [...elem.querySelectorAll('table')]

      // Wrap every table in div.table-responsive
      tables.forEach(table => {
        table.classList.add('b-table')
        const wrapper = document.createElement('div')
        wrapper.classList.add('table-responsive')
        table.parentNode.insertBefore(wrapper, table)
        wrapper.appendChild(table)
      })
    },
    setupImages() {
      const elem = this.$refs.markdown.$el
      // Get all of the images that were rendered from markdown
      // We need to know the image size, so having the image element is necesary,
      // And it wouldn't make sense to get the image url by regex and fetch it manualy again
      const images = [...elem.querySelectorAll('img')]

      const newImageList = []
      images.forEach(img => {
        img.addEventListener('click', (e) => this.zoomImage(e.target))

        const data = {
          src: img.src,
          w: img.naturalWidth,
          h: img.naturalHeight,
        }

        // Size of the image is required,
        // but we can get it only after the image is loaded
        // So a closure is created to update the size after load
        img.addEventListener('load', () => {
          data.w = img.naturalWidth
          data.h = img.naturalHeight
        })

        newImageList.push(data)
      })

      this.imageList = newImageList
    },
    zoomImage(img) {
      this.currentImageIndex = this.imageList.findIndex(image => image.src === img.src)
      this.$refs.gallery.open(this.currentImageIndex)
    },
  },
}
</script>

<style scoped>
/* Selects all child images, even if they are not part of this component */
.markdown-container >>> img {
  cursor: zoom-in;
}
</style>
