var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-nav',{attrs:{"vertical":"","pills":""}},[_c('LoadingSpinner',{attrs:{"is-loaded":_vm.$root.stateLoaded}},[_c('b-nav-item',{staticClass:"menu-entry mb-1 border-bottom",attrs:{"link-classes":"text-secondary"},on:{"click":function($event){return _vm.$emit('toggle-collapsed')}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', _vm.collapsed ? 'angle-double-right' : 'angle-double-left'],"fixed-width":""}})],1),_vm._l((_vm.$root.state.menu),function(entry){return _c('SideMenuEntry',{key:entry.label,attrs:{"entry":entry,"collapsed":_vm.collapsed}})}),(_vm.$root.seminary)?[_vm._l((_vm.$root.seminary.otherSeminary),function(seminary){return _c('SideMenuEntry',{key:seminary.title,staticClass:"d-lg-none",attrs:{"entry":{
          label: seminary.title,
          entry_type: 'section',
          url: seminary.url
        },"collapsed":_vm.collapsed}})}),_c('SideMenuEntry',{key:'P-MAT',staticClass:"d-lg-none",attrs:{"entry":{
          label: 'P-MAT',
          entry_type: 'section',
          url: 'https://p-mat.sk'
        },"collapsed":_vm.collapsed}})]:_vm._l((_vm.constants.allSeminary),function(seminary){return _c('SideMenuEntry',{key:seminary.title,staticClass:"d-lg-none",attrs:{"entry":{
          label: seminary.title,
          entry_type: 'section',
          url: seminary.url,
        },"collapsed":_vm.collapsed}})})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }