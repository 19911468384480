import constants from '../constants'

export default {
  methods: {
    apiContests() {
      return this.$root.request({
        method: constants.httpMethods.GET,
        url: 'contests/contests/',
        cache: { maxAge: constants.maxAgeDefaults.mid },
      })
    },
    apiCategories() {
      return this.$root.request({
        method: constants.httpMethods.GET,
        url: 'contests/categories/',
        cache: { maxAge: constants.maxAgeDefaults.long },
      })
    },
  },
}
