import { render, staticRenderFns } from "./HomeCarousel.vue?vue&type=template&id=9e1932cc&scoped=true&"
import script from "./HomeCarousel.vue?vue&type=script&lang=js&"
export * from "./HomeCarousel.vue?vue&type=script&lang=js&"
import style0 from "./HomeCarousel.vue?vue&type=style&index=0&id=9e1932cc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e1932cc",
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BImg, BCarouselSlide, BLink, BCarousel, BCol, BButton, BRow, BContainer, BJumbotron} from 'bootstrap-vue'
    installComponents(component, {BImg, BCarouselSlide, BLink, BCarousel, BCol, BButton, BRow, BContainer, BJumbotron})
    

export default component.exports