<template>
  <AnimatedIcon :size="size">
    <circle
      cx="0"
      cy="0"
      r="50"
      class="animated-circle success-circle"
    />
    <path
      d="M -27 2 L -7 21 23 -20"
      class="animated-path success-check"
    />
  </AnimatedIcon>
</template>

<script>
import AnimatedIcon from './AnimatedIcon.vue'

export default {
  name: 'AnimatedIconSuccess',
  components: {
    AnimatedIcon,
  },
  mixins: [],
  props: {
    size: {
      type: String,
      default: 'sm',
    },
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="scss">
.success-circle {
  stroke: var(--success);
  transform: scaleX(-1);
}

.success-check {
  stroke: var(--success);
  stroke-dasharray: 80;
  stroke-dashoffset: 80;
}

</style>
