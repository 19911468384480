<template>
  <div id="app">
    <MainNavbar
      class="main-navbar"
    />

    <b-row no-gutters>
      <b-col
        cols="auto"
        class="border-right bg-light d-none d-lg-inline"
      >
        <SideMenu
          :class="[
            'sticky-below-navbar sidebar overflow-auto text-nowrap',
            $root.storage.menuCollapsed ? '' : 'sidebar-full-width'
          ]"
          :collapsed="$root.storage.menuCollapsed"
          @toggle-collapsed="$root.storage.menuCollapsed = !$root.storage.menuCollapsed"
        />
      </b-col>
      <b-col>
        <router-view />
      </b-col>
    </b-row>

    <LoginModal />
    <SingleToast />
    <CornerAlert v-if="$root.state.liveHelpAlert">
      Nevieš odovzdať riešenia? Máš inú technickú otázku? Sme online, napíš nám na
      <a
        class="text-white text-nowrap"
        href="mailto:it@p-mat.sk"
      >
        it@p-mat.sk
      </a>
      a o pár minút sa ti budeme venovať.<br>
      <span class="small">
        Pozn.:
        <i>Riešenia zasielané na email neakceptujeme.</i>
      </span>
    </CornerAlert>
  </div>
</template>

<script>
import { CornerAlert, SideMenu, SingleToast } from 'frontend-common'
import LoginModal from '@/components/LoginModal.vue'
import MainNavbar from '@/components/MainNavbar.vue'

export default {
  name: 'MainNav',
  components: {
    CornerAlert,
    LoginModal,
    MainNavbar,
    SideMenu,
    SingleToast,
  },
  mixins: [],
  props: {},
  data() {
    return {}
  },
  watch:{
  },
  mounted() {},
  methods: {},
}
</script>
