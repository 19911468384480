import { sk } from '@braid/vue-formulate-i18n'
import FormCheckbox from './components/forms/inputs/FormCheckbox.vue'
import FormErrors from './components/forms/slots/FormErrors.vue'
import FormLabel from './components/forms/slots/FormLabel.vue'
import FormRadioButtons from './components/forms/inputs/FormRadioButtons.vue'
import FormSelect from './components/forms/inputs/FormSelect.vue'
import FormSelectCategory from './components/forms/inputs/FormSelectCategory.vue'
import FormSelectSchool from './components/forms/inputs/FormSelectSchool.vue'

export default {
  plugins: [sk],
  locale: 'sk',
  classes: {
    input: (context, classes) => {
      switch (context.type) {
      case 'submit':
        return classes.concat(['btn', 'btn-primary', 'btn-block', 'mt-2'])
      default:
        return classes.concat(['form-control'])
      }
    },
    wrapper: (context, classes) => {
      switch(context.type) {
      case 'group':
        return classes
      default:
        return classes.concat(['pt-1'])
      }
    },
    inputHasErrors: 'formulate-input-has-errors',
    // Commented out because we wanted to disable green highlighting of valid inputs.
    // inputIsValid: 'formulate-input-is-valid',
    inputHasValue: 'formulate-input-has-value',
  },
  slotComponents: {
    label: FormLabel,
    errors: FormErrors,
  },
  slotProps: {
    label: ['tooltip', 'requiredStar', 'htmlLabel'],
  },
  library: {
    checkbox: {
      classification: 'box',
      component: FormCheckbox,
      slotProps: {
        component: ['htmlLabel', 'requiredStar', 'noValidate'],
      },
    },
    radiobuttons: {
      classification: 'select',
      component: FormRadioButtons,
      slotProps: {
        component: ['currentOptions'],
      },
    },
    vselect: {
      classification: 'select',
      component: FormSelect,
      slotProps: {
        component: ['currentOptions', 'search', 'specialPrefix', 'otherTextAllowed'],
      },
    },
    school: {
      classification: 'select',
      component: FormSelectSchool,
      slotProps: {
        component: ['initialSchools'],
      },
    },
    category: {
      classification: 'select',
      component: FormSelectCategory,
      slotProps: {
        component: ['showYoungWarnings'],
      },
    },
  },
  validationNameStrategy: ['validationName', 'label', 'type'],
}
