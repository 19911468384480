<template>
  <label
    v-if="context.classification != 'box'"
    :for="context.id"
  >
    <!-- eslint-disable vue/no-v-html -->
    <span
      v-if="htmlLabel"
      v-html="context.label"
    />
    <!-- eslint-enable vue/no-v-html -->
    <span v-else>{{ context.label }}</span>
    <span class="text-danger">{{ requiredStar ? ' *' : '' }}</span>
    <InfoTooltip
      v-if="tooltip"
      :tooltip="tooltip"
    />
  </label>
</template>

<script>
import InfoTooltip from '../../utils/InfoTooltip.vue'

export default {
  name: 'FormLabel',
  components: {
    InfoTooltip,
  },
  mixins: [],
  props: {
    context: {
      type: Object,
      required: true,
    },
    htmlLabel: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: null,
    },
    requiredStar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="scss">

</style>
