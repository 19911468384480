<template>
  <b-form
    novalidate
    @submit.prevent
  >
    <b-form-group class="p-2">
      <template v-slot:label>
        Prihlasovacie údaje
        <InfoTooltip :tooltip="gdprMessages.email" />
      </template>
      <b-form-row>
        <b-col lg="6">
          <FormulateForm
            v-model="formData"
            name="registration-step-two"
            :schema="formSchema"
            :errors="fieldErrors"
            @change="e => fieldErrors[e.target.name] = []"
            @submit-raw="$refs.emailRecaptcha.reset()"
            @submit="registerEmail"
          >
            <vue-recaptcha
              ref="emailRecaptcha"
              :load-recaptcha-script="true"
              :sitekey="recaptchaSitekey"
              class="mt-4"
              @verify="recaptchaResponse = $event; $formulate.submit('registration-step-two')"
            >
              <b-button
                block
                type="submit"
                variant="primary"
                :disabled="ongoingRegistration"
              >
                <LoadingSpinner
                  :is-loaded="!ongoingRegistration"
                  small
                >
                  Zaregistrovať sa emailom
                </LoadingSpinner>
              </b-button>
            </vue-recaptcha>
          </FormulateForm>
        </b-col>
        <b-col
          xl="2"
          lg="1"
        >
          <b-row
            align-v="center"
            class="h-100 text-center"
          >
            <b-col class="m-2">
              alebo
            </b-col>
          </b-row>
        </b-col>
        <b-col
          xl="4"
          lg="5"
        >
          <b-row
            align-v="center"
            class="h-100"
          >
            <b-col>
              <vue-recaptcha
                ref="googleRecaptcha"
                :load-recaptcha-script="true"
                :sitekey="recaptchaSitekey"
                class="text-center"
                @verify="registerGoogle"
              >
                <GoogleButton
                  :is-loaded="!ongoingRegistration"
                  :disabled="ongoingRegistration"
                  label="Zaregistrovať sa pomocou Google"
                />
              </vue-recaptcha>
            </b-col>
          </b-row>
        </b-col>
      </b-form-row>
    </b-form-group>
    <b-button
      block
      size="lg"
      type="submit"
      variant="primary"
      @click.prevent="$emit('previousStep')"
    >
      <font-awesome-icon :icon="['fas', 'arrow-left']" />
      Späť na prvý krok
    </b-button>
  </b-form>
</template>

<script>
import constants from '../../constants'
import GoogleButton from './utils/GoogleButton.vue'
import InfoTooltip from '../utils/InfoTooltip.vue'
import LoadingSpinner from '../utils/LoadingSpinner.vue'
import schema from './schema'
import VueRecaptcha from 'vue-recaptcha'

export default {
  name: 'RegisterStepTwo',
  components: {
    GoogleButton,
    InfoTooltip,
    LoadingSpinner,
    VueRecaptcha,
  },
  mixins: [],
  props: {
    emailCallback: {
      type: Function,
      required: true,
    },
    googleCallback: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      formSchema: schema.applyBlurHandlers(this, [
        schema.req({...schema.email, '@change': true}, false),
        schema.row(
          schema.req({...schema.password, validation: 'min:8,length', '@change': true}, false),
          schema.req(schema.passwordConfirm, false),
        ),
      ]),
      formData: {
        email: '',
        password: '',
        password_confirm: '',
      },
      fieldErrors: {},
      recaptchaSitekey: constants.recaptchaSitekey,
      recaptchaResponse: '',
      ongoingRegistration: false,
      gdprMessages: constants.gdprMessages,
    }
  },
  computed: {},
  mounted() {},
  methods: {
    registerEmail() {
      this.ongoingRegistration = true
      this.emailCallback(this.recaptchaResponse, this.formData.email, this.formData.password).
        catch(({ response: { data } }) => {
          if (data.password && data.password[0] === 'Toto heslo je používané príliš často.') {
            data.password[0] += ' Skús pridať čísla, znaky, alebo použiť dlhšie heslo.'
          }

          this.fieldErrors = data
          this.errorAlert('Chyba počas registrácie, skontroluj údaje z oboch krokov.')
        }).
        finally(() => this.ongoingRegistration = false)
    },
    registerGoogle(recaptcha) {
      this.ongoingRegistration = true
      this.googleCallback(recaptcha).
        catch(({ response }) => {
          let message = response && response.data
            ? response.data[0]
            : 'Chyba počas registrácie pomocou Google.'
          // TODO proper translations on backend
          if (message ===
            'You already registered using this social account. Please log in instead!') {
            message = 'Už si zaregistrovaný/-á pomocou tohto účtu Google. Prosím prihlás sa!'
          }
          this.errorAlert(message)
          this.$refs.googleRecaptcha.reset()
        }).
        finally(() => this.ongoingRegistration = false)
    },
    errorAlert(text) {
      this.$root.dangerToast(text, 8000)
    },
  },
}
</script>

<style scoped>

</style>
