<template>
  <div class="position-relative">
    <div
      v-if="showContent"
      class="outer-container"
      :class="{faded: isFaded}"
    >
      <slot />
    </div>
    <div
      v-if="showSpinner"
      :class="['text-center',
               isFaded ?
                 'position-absolute d-flex justify-content-center align-items-center inset-0' :
                 '']"
      :style="fixedWidth ? `width: ${fixedWidth}px` : ``"
    >
      <b-spinner
        label="Spinning"
        :small="small"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner',
  components: {},
  mixins: [],
  props: {
    isLoaded: {
      type: Boolean,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    fixedWidth: {
      type: Number,
      default: null,
    },
    fadeOnReload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      onceLoaded: false,
    }
  },
  computed: {
    showContent() {
      return this.isLoaded || this.fadeOnReload && this.onceLoaded
    },
    isFaded() {
      return this.fadeOnReload && this.onceLoaded && !this.isLoaded
    },
    showSpinner() {
      return !this.showContent || this.isFaded
    },
  },
  watch: {
    isLoaded(newValue, oldValue) {
      this.onceLoaded = newValue || oldValue
    },
  },
  mounted() {},
  methods: {},
}
</script>

<style scoped>
.outer-container {
  transition: opacity 0.3s ease-in-out;
}

.faded {
  opacity: 0.4;
  pointer-events: none;
}

.inset-0 {
  inset: 0;
}
</style>
