export default {
  methods: {
    _track(type, value) {
      if (!window.umami) return
      window.umami.trackEvent(value, type)
    },
    trackOpenLoginModal() {
      this._track('login', 'open modal')
    },
    trackLogin(provider) {
      this._track('login', 'with ' + provider)
    },
    trackRegisterNextStep() {
      this._track('register', 'next step')
    },
    trackRegister(provider) {
      this._track('register', 'with ' + provider)
    },
    trackOpenPDFBooklet(title) {
      this._track('view_booklet', title)
    },
    trackDownloadEvaluation(analyticsProblemName) {
      this._track('download_evaluation', analyticsProblemName)
    },
    trackViewSolution(problemName) {
      this._track('view_solution', problemName)
    },
  },
}
