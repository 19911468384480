<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  mixins: [],
  props: {},
  data() {
    return {}
  },
  watch:{
  },
  mounted() {},
  methods: {},
}
</script>

<style lang="scss">
@import 'frontend-common/themes/base-app.scss';
</style>
