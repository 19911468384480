import constants from '../constants'

export default {
  methods: {
    apiProblems(roundId) {
      return this.$root.request({
        method: constants.httpMethods.GET,
        url: `contests/rounds/${roundId}/problems/`,
        cache: { maxAge: constants.maxAgeDefaults.mid },
      })
    },
    apiProblem(problemId) {
      return this.$root.request({
        method: constants.httpMethods.GET,
        url: `contests/problems/${problemId}/`,
        cache: { maxAge: constants.maxAgeDefaults.mid },
      })
    },
    apiProblemByNumber(roundId, problemNumber) {
      return this.$root.request({
        method: constants.httpMethods.GET,
        url: `contests/rounds/${roundId}/problems/${problemNumber}/`,
        cache: { maxAge: constants.maxAgeDefaults.mid },
      })
    },
    apiProblemAttachments(problemId) {
      return this.$root.request({
        method: constants.httpMethods.GET,
        url: `contests/problems/${problemId}/attachments/`,
        cache: { maxAge: constants.maxAgeDefaults.mid },
      })
    },
    apiHeadProblemAttachment(problemId, attachmentId) {
      return this.$root.request({
        method: constants.httpMethods.HEAD,
        // We use ?v2 here to force chrome to "reset" its preflight cache.
        // We observed that otherwise chrome throws CORS errors, likely due to some cache,
        // but we are unsure why and when it happens (as it is also present on fresh devices).
        url: `contests/problems/${problemId}/attachments/${attachmentId}/?v2`,
      }, true)
    },
    apiPostProblemAttachment(problemId, attachment) {
      return this.$root.request({
        method: constants.httpMethods.POST,
        url: `contests/problems/${problemId}/attachments/`,
        data: { attachment: attachment.file },
      })
    },
    apiDelProblemAttachment(problemId, attachmentId){
      return this.$root.request({
        method: constants.httpMethods.DELETE,
        url: `contests/problems/${problemId}/attachments/${attachmentId}/`,
      })
    },
    apiUpdateProblemSolution(problemId, newSolution, videoSolution) {
      return this.$root.request({
        method: constants.httpMethods.PATCH,
        url: `contests/problems/${problemId}/`,
        data: { solution: newSolution, video_solution: videoSolution },
      })
    },
    apiUpdateProblemStatement(problemId, newStatement) {
      return this.$root.request({
        method: constants.httpMethods.PATCH,
        url: `contests/problems/${problemId}/`,
        data: { statement: newStatement },
      })
    },
    apiPostProblemEvaluator(problemId, categoryId, userId) {
      return this.$root.request({
        method: constants.httpMethods.POST,
        url: 'contests/problems/evaluators/',
        data: {
          problem: problemId,
          category: categoryId,
          user: userId,
        },
      })
    },
    apiDelProblemEvaluator(evaluatorId) {
      return this.$root.request({
        method: constants.httpMethods.DELETE,
        url: `contests/problems/evaluators/${evaluatorId}/`,
      })
    },
    apiInteractiveState(interactiveId) {
      return this.$root.request({
        method: constants.httpMethods.GET,
        url: `contests/interactive/state/${interactiveId}/`,
      })
    },
    apiPutInteractiveState(interactiveId, state) {
      return this.$root.request({
        method: constants.httpMethods.PUT,
        url: `contests/interactive/state/${interactiveId}/`,
        data: state,
      })
    },
    apiVerifyInteractive(interactiveId, answers) {
      return this.$root.request({
        method: constants.httpMethods.PUT,
        url: `contests/interactive/verify/${interactiveId}/`,
        data: {
          answers,
        },
      })
    },
  },
}
