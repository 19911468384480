import { render, staticRenderFns } from "./FormSelectCategory.vue?vue&type=template&id=5b2c9e96&scoped=true&"
import script from "./FormSelectCategory.vue?vue&type=script&lang=js&"
export * from "./FormSelectCategory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../frontend/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b2c9e96",
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BLink, BModal} from 'bootstrap-vue'
    installComponents(component, {BLink, BModal})
    


    import installDirectives from "!/Users/jurajpetras/dev/pmat/web/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installDirectives.js"
    import {VBModal} from 'bootstrap-vue'
    installDirectives(component, {'b-modal': VBModal})
    

export default component.exports