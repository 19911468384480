import constants from '../constants'

export default {
  methods: {
    apiStatus() {
      return this.$root.request({
        method: constants.httpMethods.GET,
        url: 'status/',
      })
    },
  },
}
