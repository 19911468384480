<template>
  <b-nav-item
    :to="computeRoute"
    :href="computeUrl"
    :disabled="!computeRoute && !computeUrl"
    :class="['menu-entry', entry.entry_type === 'section' ? 'font-weight-bold border-top' : '']"
    :exact="entry.is_exact"
    active-class="active"
  >
    <font-awesome-icon
      v-if="entry.icon_name"
      :icon="[entry.icon_family, entry.icon_name]"
      :class="entry.entry_type === 'section' || collapsed ? '' : 'ml-4'"
      fixed-width
    />
    <span
      v-if="!collapsed"
    >
      {{ entry.label }}
    </span>
  </b-nav-item>
</template>

<script>
export default {
  name: 'SideMenuEntry',
  components: {},
  mixins: [],
  props: {
    entry: {
      type: Object,
      required: true,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    computeUrl() {
      return this.entry.url || null
    },
    computeRoute() {
      if (this.entry.page) return { name: 'page', params: { pageSlug: this.entry.page }}
      return this.entry.custom_name ? { name: this.entry.custom_name } : null
    },
  },
  mounted() {},
  methods: {},
}
</script>

<style scoped>
a.disabled {
  color: var(--dark);
}
</style>
