import * as Sentry from '@sentry/browser'
import apiStatus from '../api/status'
import constants from '../constants'

export default {
  mixins: [
    apiStatus,
  ],
  data: {
    // data in storage is permanent (it survives reload and is updated explicitly)
    storage: {
      token: '',
      menuCollapsed: false,
      firstTimeVisitor: true,
      selectedContestGroup: constants.contestGroups.ALL,
    },
    // data in state lives only during the session and is updated implicitly on page load/login/...
    // Beware! When reading state, you should always wrap the component in v-if="stateLoaded" or
    // something similar and bind it reactively (eg. be cautious when using it in method bodies).
    state: {
      menu: [],
      user: {},
      competition: {},
      homeBannerLeft: {},
      homeBannerRight: {},
      liveHelpAlert: false,
      event: null,
      outdatedProfile: false,
    },
    stateLoaded: false,
  },
  computed: {
    isAuthenticated() {
      return this.storage.token !== ''
    },
    userCategory() {
      const competition = this.state.competition
      if (competition.current_round?.results?.row?.enrollment) {
        return competition.current_round.results.row.enrollment.category
      }
      if (this.state.user && this.state.user.competitor) {
        return this.state.user.competitor.category
      }
      return {}
    },
    previousUserCategory() {
      const competition = this.state.competition
      if (competition.previous_round?.results?.row?.enrollment) {
        return competition.previous_round.results.row.enrollment.category
      }
      return this.userCategory
    },
  },
  created() {
    window.addEventListener('storage', this.syncFromLocalStorage)
  },
  mounted() {
    this.loadFromLocalStorage()
    this.loadStatus()
  },
  watch: {
    storage: {
      handler(newStorage) {
        this.syncToLocalStorage(newStorage)
      },
      deep: true,
    },
  },
  methods: {
    setIfExists(key, value) {
      // Sets a value from localStorage into storage,
      // if the key already exists in storage.
      // This way we won't get random things into our storage.
      try {
        if (key in this.storage) {
          this.storage[key] = JSON.parse(value)
        }
      } catch (e) {
        if (e instanceof SyntaxError)
          return
        throw e
      }
    },
    loadFromLocalStorage() {
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i)
        this.setIfExists(key, localStorage.getItem(key))
      }
    },
    syncFromLocalStorage(e) {
      this.setIfExists(e.key, e.newValue)
    },
    syncToLocalStorage(newStorage) {
      for (const key in newStorage) {
        localStorage.setItem(key, JSON.stringify(newStorage[key]))
      }
    },
    setAuthToken(token) {
      this.storage.token = token
      return this.loadStatus()
    },
    unsetAuthToken() {
      this.storage.token = ''
      // TODO find a way to catch specifically the redundant navigation error
      this.$router.push('/').catch(() => {})
      this.loadStatus()
    },
    loadStatus() {
      this.stateLoaded = false
      return this.silentlyLoadStatus().finally(() => this.stateLoaded = true)
    },
    silentlyLoadStatus() {
      return this.apiStatus().
        then(response => {
          this.state.menu = response.menu
          this.state.user = response.user
          this.state.competition = response.competition
          this.state.homeBannerLeft = response.state.home_banner_left
          this.state.homeBannerRight = response.state.home_banner_right
          this.state.yearStart = response.state.year_start
          this.state.liveHelpAlert = !!response.state.live_help
          this.state.event = response.event

          if (this.state.user?.competitor) {
            const now = new Date()
            const yearStart = new Date(now.getFullYear(), 7, 1)

            if (now.getMonth() < 7) {
              yearStart.setFullYear(now.getFullYear() - 1)
            }

            this.state.outdatedProfile = new Date(this.state.user.competitor.updated_at) < yearStart
          } else {
            this.state.outdatedProfile = false
          }

          if (response.user) {
            Sentry.setUser({
              email: response.user.email,
              id: response.user.id,
            })
            if (this.$root.exponea)
              this.$root.exponeaIdentify(response.user)
          }
        })
    },
    silentlyLoadStatusDelayed() {
      setTimeout(() => this.silentlyLoadStatus(), constants.statusLoadDelay)
    },
    isProblemRecommendedForMe(problem) {
      return problem.categories.some(category =>
        category.recommended && category.name === this.userCategory.name)
    },
    isProblemAllowedForMe(problem) {
      return problem.categories.some(category => category.name === this.userCategory.name)
    },
  },
}
