export default {
  methods: {
    dangerToast(text, duration) {
      this.$root.$emit('toast', 'danger', text, duration)
    },
    infoToast(text, duration) {
      this.$root.$emit('toast', 'info', text, duration)
    },
    successToast(text, duration) {
      this.$root.$emit('toast', 'success', text, duration)
    },
    warningToast(text, duration) {
      this.$root.$emit('toast', 'warning', text, duration)
    },
    hideToast() {
      this.$root.$emit('toast', null, '', 0)
    },
  },
}
