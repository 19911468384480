import { render, staticRenderFns } from "./SideMenu.vue?vue&type=template&id=3c405ff9&scoped=true&"
import script from "./SideMenu.vue?vue&type=script&lang=js&"
export * from "./SideMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../frontend/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c405ff9",
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BNavItem, BNav} from 'bootstrap-vue'
    installComponents(component, {BNavItem, BNav})
    

export default component.exports