<!-- When adding a 'component' that contains HTML to a Formulate form (eg. a help text), the v-html
prop is not evaluated by Vue. Therefore an intermediate component like this is necessary. -->

<template>
  <!-- eslint-disable vue/no-v-html -->
  <div v-html="content" />
  <!-- eslint-enable vue/no-v-html -->
</template>

<script>
export default {
  name: 'FormHelpText',
  components: {},
  mixins: [],
  props: {
    content: {
      type: String,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {},
  methods: {},
}
</script>
