import { render, staticRenderFns } from "./NotFound.vue?vue&type=template&id=6705b904&scoped=true&"
import script from "./NotFound.vue?vue&type=script&lang=js&"
export * from "./NotFound.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../frontend/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6705b904",
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BImg, BCol, BRow} from 'bootstrap-vue'
    installComponents(component, {BImg, BCol, BRow})
    

export default component.exports