<template>
  <font-awesome-icon
    v-b-popover.hover.html="tooltip"
    size="sm"
    class="ml-1 text-info"
    :icon="['far', 'question-circle']"
  />
</template>

<script>
export default {
  name: 'InfoTooltip',
  components: {},
  mixins: [],
  props: {
    tooltip: {
      type: String,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {},
}
</script>

<style scoped>

</style>
