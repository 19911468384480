<template>
  <div>
    <HomeCarousel />
    <HomeProfileUpdateBanner v-if="$root.stateLoaded && $root.state.outdatedProfile" />
    <HomeWideBanner
      v-if="$root.stateLoaded && $root.state.event"
      :data="$root.state.event"
    />
    <div class="slanted bg-secondary" />
    <div class="overlap mx-2">
      <b-container fluid>
        <LoadingSpinner :is-loaded="$root.stateLoaded">
          <b-row
            align-h="around"
            class="text-center m-0"
          >
            <b-col
              cols="12"
              md="8"
              xl="5"
              class="mb-5"
            >
              <HomeBanner
                v-if="$root.state.homeBannerLeft"
                :data="$root.state.homeBannerLeft"
              />
            </b-col>
            <b-col
              cols="12"
              md="8"
              xl="5"
              class="mb-5"
            >
              <HomeBanner
                v-if="$root.state.homeBannerRight"
                :data="$root.state.homeBannerRight"
              />
            </b-col>
          </b-row>
        </LoadingSpinner>

        <b-row>
          <b-col lg="8">
            <PostList />
          </b-col>

          <b-col lg="4">
            <div class="p-2">
              <b-link
                v-for="{ link, image, alt } of institutions"
                :key="image"
                target="_blank"
                :href="link"
              >
                <b-img
                  :src="image"
                  fluid
                  center
                  :alt="alt"
                />
              </b-link>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import { LoadingSpinner } from 'frontend-common'
import HomeBanner from '@/components/HomeBanner.vue'
import HomeCarousel from '@/components/HomeCarousel.vue'
import HomeProfileUpdateBanner from '@/components/HomeProfileUpdateBanner'
import HomeWideBanner from '@/components/HomeWideBanner.vue'
import PostList from '@/components/PostList.vue'

export default {
  name: 'Home',
  components: {
    PostList,
    HomeBanner,
    HomeWideBanner,
    HomeProfileUpdateBanner,
    HomeCarousel,
    LoadingSpinner,
  },
  mixins: [],
  props: {},
  data() {
    return {
      institutions: [
        {
          link: 'https://p-mat.sk/',
          image: '/logos/institutions/pmat-horizontal.svg',
          alt: 'P-MAT, n.o.',
        },
        {
          link: 'https://www.minedu.sk/',
          image: '/logos/institutions/minedu-horizontal.svg',
          alt: 'MŠVVaŠ SR',
        },
        {
          link: 'https://www.employment.gov.sk/sk/esf/',
          image: '/logos/institutions/eu-horizontal.svg',
          alt: 'Európska únia',
        },
        {
          link: 'https://www.ludskezdroje.gov.sk/',
          image: '/logos/institutions/oplz-horizontal.svg',
          alt: 'Operačný program Ľudské zdroje',
        },
      ],
    }
  },
  computed: {},
  mounted() {},
  methods: {},
}
</script>

<style scoped>
.slanted {
  position: relative;
  top: -2px;
  height: 20rem;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 45%, 0 100%);
  clip-path:         polygon(0 0, 100% 0, 100% 45%, 0 100%);
}

.overlap {
  margin-top: -17rem;
}
</style>
