<template>
  <b-container class="my-3">
    <b-button
      :to="{ name: 'profile-detail' }"
      variant="outline-info"
      class="my-3"
      exact
    >
      <font-awesome-icon :icon="['fas', 'angle-double-left']" /> Späť na profil
    </b-button>
    <h1>Nastavenia účtu</h1>
    <ProfileChangeForm />
    <b-row
      align-h="center"
      class="mt-5"
    >
      <b-col
        sm="8"
        md="6"
        lg="4"
      >
        <PasswordChangeForm />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import PasswordChangeForm from '../components/forms/PasswordChangeForm.vue'
import ProfileChangeForm from '../components/forms/ProfileChangeForm.vue'

export default {
  name: 'ProfileChange',
  components: {
    PasswordChangeForm,
    ProfileChangeForm,
  },
  mixins: [],
  props: {},
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {},
}
</script>

<style scoped>
</style>
