<template>
  <LoadingSpinner :is-loaded="$root.stateLoaded">
    <h1>Záväzná prihláška</h1>
    <FormulateForm
      v-model="formData"
      name="attendance"
      :schema="formSchema"
      @submit="submitForm"
    >
      <b-alert
        variant="warning"
        class="text-center"
        show
      >
        <div>
          Niektoré z údajov sme predvyplnili z tvojho profilu, prosíme ťa
          o ich kontrolu pred odoslaním.
        </div>
        <div>
          Po odoslaní už nie je možné údaje meniť.
        </div>
      </b-alert>
      <b-button
        block
        type="submit"
        variant="primary"
        :disabled="ongoingSubmit"
      >
        <LoadingSpinner
          :is-loaded="!ongoingSubmit"
          small
        >
          Odoslať prihlášku
        </LoadingSpinner>
      </b-button>
    </FormulateForm>
  </LoadingSpinner>
</template>

<script>
import apiEvents from '../../api/events'
import constants from '../../constants'
import LoadingSpinner from '../utils/LoadingSpinner.vue'
import schema from './schema'

const {
  addressGroup, birthNumber, customPrice, discountRequest, email, firstName, health,
  lastName, other, parentGroup, phone, price, req, row, terms, transport, visualGroup,
  group, applyBlurHandlers, eventTerms,
} = schema

export default {
  name: 'EventForm',
  components: {
    LoadingSpinner,
  },
  mixins: [
    apiEvents,
  ],
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formData: {},
      ongoingSubmit: false,
    }
  },

  computed: {
    formSchema() {
      const priceOptions = Object.entries(this.event.payment.options).sort((a, b) => {
        return a[1].price - b[1].price
      }).map(([key, optionInfo]) =>
        ({value: key, label: `${optionInfo.name} - ${optionInfo.price} €`}))
      priceOptions.push(constants.forms.customPrice)
      priceOptions.push(constants.forms.discountRequest)
      const minPrice = this.event.payment.minimum
      return applyBlurHandlers(this, [
        row([
          visualGroup('Údaje riešiteľa', [group('attendee', [
            row(req(firstName, 'eventFirstName'), req(lastName, 'eventLastName')),
            row(req(email, 'eventEmail'), req(phone, 'eventPhone')),
          ])]),
          parentGroup,
          visualGroup('Doplňujúce údaje', [
            req({
              ...transport,
              currentOptions: this.event.transport.options,
              otherTextAllowed: this.event.transport.other,
            }),
            ...this.event.additional ?
              [group('additional', this.event.additional)] : [],
            other,
            terms,
            eventTerms,
          ]),
        ], [
          addressGroup,
          visualGroup('Citlivé údaje', [
            req(birthNumber),
            health,
          ]),
          req({...price, currentOptions: priceOptions}),
          ...this.formData.price_name === constants.forms.customPrice.value ? [{
            ...customPrice, validation: `number|min:${minPrice},value`,
            help: `Minimálna výška ceny je ${minPrice} €.`,
          }]: [],
          ...this.formData.price_name === constants.forms.discountRequest.value ?
            [req(discountRequest)]: [],
        ]),
      ])
    },
  },
  watch: {
    '$root.stateLoaded': 'updateFromState',
  },
  mounted() {
    this.updateFromState()
  },
  methods: {
    updateFromState() {
      if (!this.$root.stateLoaded) return
      const user = this.$root.state.user

      this.formData = {
        attendee: [{
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          phone: user.sensitive ? user.sensitive.phone : null,
        }],
        parent: user.competitor ? [{
          first_name: user.competitor.parent_first_name,
          last_name: user.competitor.parent_last_name,
          phone: user.competitor.parent_phone,
          email: user.competitor.parent_email,
        }] : [{}],
        additional: [{}],
        address: user.sensitive ? [{
          ...user.sensitive.address,
          country: {label: user.sensitive.address.country,
                    value: user.sensitive.address.country},
        }] : [{}],
      }
    },
    submitForm(data) {
      this.ongoingSubmit = true
      setTimeout(() => this.ongoingSubmit = false, 2000)

      if (data.discount_request) {
        data.price_name = Object.keys(this.event.payment.options)[0]
      }
      const postData = {
        parent: data.parent[0],
        attendee: {
          ...data.attendee[0],
          birth_number: data.birth_number,
          address: {
            ...data.address[0],
            country: data.address[0].country.value,
          },
        },
        payment: {
          price: data.custom_price ? data.custom_price :
            this.event.payment.options[data.price_name].price,
          discount_request: data.discount_request || '',
        },

        transport: data.transport.value === 'other' ? data.transport.other : data.transport.label,
        other: data.other || '',
        address: {...data.address[0], country: data.address[0].country.value},
        health: data.health || '',
      }
      if (data.additional) {
        postData.additional = data.additional[0]
        this.event.additional.forEach(field => {
          if (field.type === 'vselect') {
            postData.additional[field.name] = data.additional[0][field.name].value
          }
        })
      }

      this.apiUpdateEventAttendance(this.event.id, postData).
        then(() => {
          this.$root.successToast('Prihláška bola úspešne odoslaná.')
          this.$root.loadStatus()
          this.$emit('attendanceCreated')
        }).
        catch(() => {
          this.$root.dangerToast(
            'Nastala chyba pri zasielaní prihlášky. Prosím skontroluj svoje údaje.',
          )
        }).
        finally(() => this.ongoingSubmit = false)
    },
  },
}
</script>

<style scoped>
</style>
