<template>
  <div>
    <b-progress
      class="mb-2"
      :max="totalSteps"
      show-value
    >
      <b-progress-bar
        label="1. krok – osobné údaje"
        value="1"
        :variant="currentStep >= 1 ? 'primary' : 'dark'"
        :striped="currentStep !== 1"
      />
      <b-progress-bar
        label="2. krok – prihlasovacie údaje"
        value="1"
        :variant="currentStep >= 2 ? 'primary' : 'dark'"
        :striped="currentStep !== 2"
      />
    </b-progress>
    <keep-alive>
      <RegisterStepOne
        v-if="currentStep === 1"
        @nextStep="dataStepOne = $event; nextStep()"
      />

      <RegisterStepTwo
        v-else
        :email-callback="registerEmail"
        :google-callback="registerGoogle"
        @previousStep="previousStep"
      />
    </keep-alive>
  </div>
</template>

<script>
import analytics from '../../mixins/analytics'
import apiUsers from '../../api/users'
import RegisterStepOne from './RegisterStepOne.vue'
import RegisterStepTwo from './RegisterStepTwo.vue'

export default {
  name: 'RegisterForm',
  components: {
    RegisterStepOne,
    RegisterStepTwo,
  },
  mixins: [
    apiUsers,
    analytics,
  ],
  props: {},
  data() {
    return {
      currentStep: 1,
      totalSteps: 2,
      dataStepOne: {},
      dataStepTwo: {},
      recaptcha: '',
    }
  },
  computed: {
    registrationData() {
      return {...this.dataStepOne, ...this.dataStepTwo, recaptcha: this.recaptcha}
    },
  },
  mounted() {},
  methods: {
    previousStep() {
      this.currentStep = Math.max(1, this.currentStep - 1)
    },
    nextStep() {
      this.currentStep = Math.min(this.totalSteps, this.currentStep + 1)
    },
    registerEmail(recaptcha, email, password) {
      this.recaptcha = recaptcha
      this.dataStepTwo = {email, password}
      return this.apiRegisterEmail(this.registrationData).
        then(() => {
          this.$emit('login-modal-close')
          this.$root.successToast('Registrácia úspešná!')
          this.$router.push({name: 'email-verification', query: {email: this.dataStepTwo.email}})
          this.trackRegister('email')
        })
    },
    registerGoogle(recaptcha) {
      this.recaptcha = recaptcha
      return this.$googleClient.loginPopup().
        then(auth_code => this.apiRegisterGoogle(auth_code, this.registrationData)).
        then(response => {
          this.$root.setAuthToken(response.key)
          this.$emit('login-modal-close')
          this.$root.successToast('Registrácia úspešná!')
          this.trackRegister('Google')
        })
    },
  },
}
</script>

<style scoped>

</style>
