<template>
  <b-card
    :title="post.title"
    tag="article"
    class="shadow"
    no-body
  >
    <b-row
      align-v="center"
      no-gutters
    >
      <b-col
        cols="12"
        xl="4"
        class="text-center"
      >
        <b-img
          :src="post.thumbnail"
          fluid
          fluid-grow
        />
      </b-col>
      <b-col>
        <b-card-body>
          <b-card-text>
            <vue-markdown :source="post.content" />
          </b-card-text>
          <b-card-text class="text-muted">
            Autor: {{ post.author }}, {{ postDate }}
          </b-card-text>
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { utils } from 'frontend-common'
import VueMarkdown from 'vue-markdown'

export default {
  name: 'Post',
  components: {
    VueMarkdown,
  },
  mixins: [],
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    postDate() {
      return utils.dateToString(this.post.date_created)
    },
  },
  mounted() {},
  methods: {},
}
</script>

<style scoped>

</style>
