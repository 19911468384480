import constants from '../constants'

export default {
  methods: {
    apiResultTables() {
      return this.$root.request({
        method: constants.httpMethods.GET,
        url: 'results/tables/',
        cache: { maxAge: constants.maxAgeDefaults.mid },
      })
    },
    apiResultTable(tableId) {
      return this.$root.request({
        method: constants.httpMethods.GET,
        url: `results/tables/${tableId}/`,
        cache: { maxAge: constants.maxAgeDefaults.mid },
      })
    },
    apiResultsPersonal() {
      return this.$root.request({
        method: constants.httpMethods.GET,
        url: 'results/personal/',
        cache: { maxAge: constants.maxAgeDefaults.mid },
      })
    },
  },
}
