import constants from '../constants'

export default {
  methods: {
    apiEvents() {
      return this.$root.request({
        method: constants.httpMethods.GET,
        // As non-camp events are not well supported right now, we use only those here
        url: `events/events/`,
        cache: { maxAge: constants.maxAgeDefaults.mid },
      })
    },
    apiEvent(eventId) {
      return this.$root.request({
        method: constants.httpMethods.GET,
        url: `events/events/${eventId}/`,
        cache: { maxAge: constants.maxAgeDefaults.mid },
      })
    },
    apiUpdateEventAttendance(eventId, data) {
      return this.$root.request({
        method: constants.httpMethods.PUT,
        url: `events/events/${eventId}/attendance/`,
        data,
      })
    },
    apiDeclineEvent(eventId) {
      return this.$root.request({
        method: constants.httpMethods.POST,
        url: `events/events/${eventId}/decline/`,
      })
    },
    apiUndeclineEvent(eventId) {
      return this.$root.request({
        method: constants.httpMethods.POST,
        url: `events/events/${eventId}/undecline/`,
      })
    },
    apiPayBySquare(eventId) {
      return this.$root.showImage({
        url: `events/events/${eventId}/attendance/pay-by-square`,
      })
    },
  },
}
