import { render, staticRenderFns } from "./PasswordResetModalForm.vue?vue&type=template&id=3f50d3aa&"
import script from "./PasswordResetModalForm.vue?vue&type=script&lang=js&"
export * from "./PasswordResetModalForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../frontend/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BButton, BModal} from 'bootstrap-vue'
    installComponents(component, {BButton, BModal})
    

export default component.exports