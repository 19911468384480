import { render, staticRenderFns } from "./PersonalResultTable.vue?vue&type=template&id=bc84e966&scoped=true&"
import script from "./PersonalResultTable.vue?vue&type=script&lang=js&"
export * from "./PersonalResultTable.vue?vue&type=script&lang=js&"
import style0 from "./PersonalResultTable.vue?vue&type=style&index=0&id=bc84e966&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc84e966",
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BLink} from 'bootstrap-vue'
    installComponents(component, {BLink})
    


    import installDirectives from "!/Users/jurajpetras/dev/pmat/web/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installDirectives.js"
    import {VBPopover} from 'bootstrap-vue'
    installDirectives(component, {'b-popover': VBPopover})
    

export default component.exports