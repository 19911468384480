<template>
  <b-dropdown
    ref="dropdown"
    text="Iný ročník/séria"
    menu-class="scrollable-dropdown-menu"
    :variant="variant"
    :right="right"
  >
    <b-dropdown-text
      v-for="contest of contests"
      :key="contest.id"
      class="text-nowrap"
    >
      <div class="mt-2 mb-2 h6">
        <span v-if="showGroup">{{ contest.group }}</span>
        {{ contest.name }}
      </div>
      <DangerButton
        v-for="round of roundsByContest(contest.id)"
        :key="round.id"
        :to="urlNavigation ? {
          name: viewName,
          params: { roundId: round.id }
        } : undefined"
        size="sm"
        variant="primary"
        class="mr-1"
        active-class="active"
        :danger="!round.visible"
        danger-tooltip="Táto séria nie je zverejnená pre riešiteľov!"
        @click="clicked(round)"
      >
        {{ round.name }}
      </DangerButton>
    </b-dropdown-text>
  </b-dropdown>
</template>

<script>
import { DangerButton } from '../index'

export default {
  name: 'RoundSelectionDropdown',
  components: {
    DangerButton,
  },
  mixins: [ ],
  props: {
    contests: {
      type: Array,
      required: true,
    },
    right: {
      type: Boolean,
      default: false,
    },
    showGroup: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'outline-light',
    },
    rounds: {
      type: Array,
      required: true,
    },
    viewName: {
      type: String,
      required: true,
    },
    urlNavigation: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    roundsByContest(contestId) {
      return this.rounds.filter(round => round.contest.id === contestId)
    },
    clicked(round) {
      this.$emit('roundChanged', round)
      this.$refs.dropdown.hide(true)
    },
  },
}
</script>

<style scoped>

</style>
