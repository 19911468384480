<template>
  <!-- Root element of PhotoSwipe. Must have class pswp. -->
  <div
    class="pswp"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <!-- Background of PhotoSwipe.
         It's a separate element, as animating opacity is faster than rgba(). -->
    <div class="pswp__bg" />

    <!-- Slides wrapper with overflow:hidden. -->
    <div class="pswp__scroll-wrap">
      <!-- Container that holds slides. PhotoSwipe keeps only 3 slides in DOM to save memory. -->
      <div class="pswp__container">
        <!-- don't modify these 3 pswp__item elements, data is added later on -->
        <div class="pswp__item" />
        <div class="pswp__item" />
        <div class="pswp__item" />
      </div>

      <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
      <div class="pswp__ui pswp__ui--hidden">
        <div class="pswp__top-bar">
          <!--  Controls are self-explanatory. Order can be changed. -->

          <div class="pswp__counter" />

          <button
            class="pswp__button pswp__button--close"
            title="Zatvoriť (Esc)"
          />

          <button
            class="pswp__button pswp__button--fs"
            title="Prepnúť zobrazenie na celú obrazovku"
          />

          <button
            class="pswp__button pswp__button--zoom"
            title="Zväčšiť/Zmenšiť"
          />

          <!-- Preloader demo https://codepen.io/dimsemenov/pen/yyBWoR -->
          <!-- element will get class pswp__preloader--active when preloader is running -->
          <div class="pswp__preloader">
            <div class="pswp__preloader__icn">
              <div class="pswp__preloader__cut">
                <div class="pswp__preloader__donut" />
              </div>
            </div>
          </div>
        </div>

        <button
          class="pswp__button pswp__button--arrow--left"
          title="Predošlá fotka (←)"
        />

        <button
          class="pswp__button pswp__button--arrow--right"
          title="Ďaľšia fotka (→)"
        />

        <div class="pswp__caption">
          <div class="pswp__caption__center" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'photoswipe/dist/default-skin/default-skin.css'
import 'photoswipe/dist/photoswipe.css'
import * as PhotoSwipe from 'photoswipe'
import * as PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'
export default {
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      gallery: null,
    }
  },
  methods: {
    open(idx) {
      const options = {
        index: idx,
        closeOnScroll: false,
      }
      this.gallery = new PhotoSwipe(this.$el, PhotoSwipeUI_Default, this.images, options)
      this.gallery.init()
    },
  },
}
</script>

<style>
.pswp__zoom-wrap > img {
  background-color: #fff;
}
</style>
