<template>
  <div>
    <p>
      Ak si chceš zmeniť meno alebo email, napíš nám na
      <a href="mailto:p-mat@p-mat.sk">p-mat@p-mat.sk</a>.
    </p>
    <b-alert
      v-if="formData.competitor"
      variant="warning"
      show
    >
      Ak si už odovzdal/-a v tejto časti aspoň jednu úlohu,
      <strong>zmena kategórie alebo školy</strong> sa prejaví až budúci polrok.
      V prípade, že je potrebné vykonať zmenu už teraz, napíš nám na
      <a href="mailto:p-mat@p-mat.sk">p-mat@p-mat.sk</a>.
    </b-alert>
    <LoadingSpinner :is-loaded="$root.stateLoaded">
      <FormulateForm
        v-model="formData"
        name="profile-change"
        :schema="formSchema"
        @submit="confirmSaveProfile"
      >
        <div class="text-center mt-3">
          <b-button
            size="lg"
            type="submit"
            variant="primary"
            :disabled="savingProfile"
            @click.prevent="$formulate.submit('profile-change')"
          >
            <LoadingSpinner :is-loaded="!savingProfile">
              <font-awesome-icon :icon="['fas', 'check']" />
              <template v-if="$root.state.outdatedProfile">
                Uvedené údaje sú aktuálne
              </template>
              <template v-else>
                Uložiť profil
              </template>
            </LoadingSpinner>
          </b-button>
        </div>
      </FormulateForm>
    </LoadingSpinner>
  </div>
</template>

<script>
import apiUsers from '../../api/users'
import LoadingSpinner from '../utils/LoadingSpinner.vue'
import schema from './schema'

const {
  category, city, country, email, firstName, group, lastName, maskedIdentity, phone, postalNumber,
  publicProfile, role,
  req, row, school, sex, street, streetNumber, visualGroup, applyBlurHandlers,
} = schema

export default {
  name: 'ProfileChangeForm',
  components: {
    LoadingSpinner,
  },
  mixins: [
    apiUsers,
  ],
  props: {},
  data() {
    return {
      formSchema: [],
      formData: {},
      originalData: {},
      savingProfile: false,
    }
  },
  computed: {},
  watch: {
    '$root.stateLoaded': 'setUserData',
  },
  mounted() {
    this.setUserData()
  },
  methods: {
    getUserDataFromState() {
      const base = JSON.parse(JSON.stringify(this.$root.state.user))

      // We clean the options which are not supposed to be changed or are empty
      if (!base.organizer) delete base.organizer
      if (!base.competitor) delete base.competitor
      if (!base.sensitive) delete base.sensitive
      delete base.first_name
      delete base.last_name
      delete base.full_name
      delete base.email

      return base
    },
    setFormSchema(data) {
      const groups = []

      if (data.competitor) {
        groups.push([
          visualGroup('Údaje riešiteľa', [group('competitor', [
            row(req(school, false), [req(category, false), req(sex, false)]),
          ])]),
          visualGroup('Údaje rodiča', [group('parent', [
            row({...firstName, tooltip: null}, {...lastName, tooltip: null}),
            row({...email, tooltip: null}, {...phone, tooltip: null}),
          ])]),
        ])
      }
      if (data.organizer) {
        groups.push([
          visualGroup('Údaje vedúceho', [group('organizer', [
            row(publicProfile, role),
          ])]),
        ])
      }
      if (data.sensitive) {
        const sensitiveGroup = [
          visualGroup('Citlivé údaje', [group('sensitive', [
            group('address', [
              row(req(street), req(streetNumber)),
              req(city),
              row(req(postalNumber), req(country)),
            ]),
            row({...phone, tooltip: null}, []),
          ])]),
        ]

        if (data.competitor)
          sensitiveGroup.push(maskedIdentity)
        groups.push(sensitiveGroup)
      }

      this.formSchema = applyBlurHandlers(this, [row(...groups)])
    },
    setUserData() {
      // A dirty way to create a copy of the user data
      const data = this.getUserDataFromState()
      this.setFormSchema(data)

      if (data.competitor) {
        const school = data.competitor.school
        const category = data.competitor.category
        data.competitor.school = {label: school.name, value: school.id}
        data.competitor.category = {label: category.name, value: category.id}
        // Keep the original school and category data for the confirmation modal
        this.originalData = { school: school.id, category: category.id }
        data.competitor.sex = schema.sex.currentOptions.find(({value}) => value === data.sex)
        data.parent = {
          first_name: data.competitor.parent_first_name || '',
          last_name: data.competitor.parent_last_name || '',
          email: data.competitor.parent_email || '',
          phone: data.competitor.parent_phone || '',
        }
        data.competitor = [data.competitor]
        data.parent = [data.parent]
      }
      if (data.organizer) {
        data.organizer = [
          {role: data.organizer.role, public_profile: data.organizer.publicProfile},
        ]
      }
      if (data.sensitive) {
        const country = data.sensitive.address.country
        data.sensitive.address.country = {label: country, value: country}
        data.sensitive.address = [data.sensitive.address]
        data.sensitive = [data.sensitive]
      }
      this.formData = data
    },
    async confirmSaveProfile() {
      if (this.formData.competitor) {
        const competitor = this.formData.competitor[0]
        if (competitor.school.value !== this.originalData.school &&
          !await this.$root.warningModalConfirm(
            'Naozaj chceš zmeniť školu?',
            'Ak si už odovzdal/-a v tejto časti aspoň jednu úlohu, ' +
              'zmena školy sa prejaví až budúci polrok',
          ))
          return

        if (competitor.category.value !== this.originalData.category &&
          !await this.$root.warningModalConfirm(
            'Naozaj chceš zmeniť kategóriu?',
            'Ak si už odovzdal/-a v tejto časti aspoň jednu úlohu, ' +
              'zmena kategórie sa prejaví až budúci polrok',
          ))
          return
      }
      this.saveProfile()
    },
    // It really does not make sense to split this method anymore.
    // eslint-disable-next-line complexity
    saveProfile() {
      this.savingProfile = true
      const data = {
        masked_identity: this.formData.masked_identity || false,
      }
      if (this.formData.sensitive) {
        const address = this.formData.sensitive[0].address[0]
        data.sensitive = {
          address: {...address, country: address.country.value},
          phone: this.formData.sensitive[0].phone || '',
        }
      }
      if (this.formData.organizer) {
        data.organizer = {role: this.formData.organizer[0].role,
                          public_profile: this.formData.organizer[0].public_profile || false}
      }
      if (this.formData.competitor) {
        const competitor = this.formData.competitor[0]
        const parent = this.formData.parent[0]
        data.competitor = {
          school: competitor.school.value,
          category: competitor.category.value,
          parent_first_name: parent.first_name || '',
          parent_last_name: parent.last_name || '',
          parent_email: parent.email || '',
          parent_phone: parent.phone || '',
        }
        data.sex = competitor.sex.value
      }

      this.apiPatchUser(this.formData.id, data).
        then(() => {
          this.$root.successToast('Zmeny boli uložené.')
          this.$root.silentlyLoadStatus()
          if (this.formData.competitor) {
            const competitor = this.formData.competitor[0]
            this.originalData = {
              school: competitor.school.value, category: competitor.category.value,
            }
          }
        }).
        catch(() => {
          this.$root.dangerToast('Nastala chyba pri ukladaní zmien.')
        }).
        finally(() => this.savingProfile = false)
    },
  },
}
</script>

<style scoped>

</style>
