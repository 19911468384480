<template>
  <b-row class="bg-primary text-white shadow text-center p-2 p-sm-4 h-100">
    <b-col class="align-self-center">
      <template v-if="data.type == 'round'">
        <h1>{{ roundTitle }}</h1>
        <template v-if="round">
          <h3>
            {{ round.name }}
          </h3>
          <PersonalResultTable
            v-if="$root.isAuthenticated"
            :round="round"
            class="mt-3"
          />
          <b-row align-h="center">
            <HomeBannerButton
              :icon="['fas', 'tasks']"
              title="Úlohy"
              :to="{ name: 'round', params: { roundId: round.id } }"
            />
            <HomeBannerButton
              v-if="roundShowResults"
              :icon="['fas', 'trophy']"
              title="Výsledky"
              :to="{
                name: 'results',
                params: { roundId: round.id },
                hash: resultsHash,
              }"
            />
          </b-row>
          <p class="mb-1">
            Koniec série {{ utils.dateFromNow(round.end_time) }}
          </p>
        </template>
      </template>

      <template v-else>
        <h1>{{ data.title }}</h1>
        <vue-markdown
          :source="data.content"
          class="mt-4"
        />
      </template>
    </b-col>
  </b-row>
</template>

<script>
import { utils } from 'frontend-common'
import HomeBannerButton from '@/components/HomeBannerButton.vue'
import PersonalResultTable from '@/components/PersonalResultTable.vue'
import VueMarkdown from 'vue-markdown'

export default {
  name: 'HomeBanner',
  components: {
    HomeBannerButton,
    VueMarkdown,
    PersonalResultTable,
  },
  mixins: [],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      utils,
    }
  },
  computed: {
    roundTitle() {
      return this.data.key === 'current_round' ? 'Aktuálna séria' : 'Minulá séria'
    },
    resultsHash() {
      let category = null
      if (this.data.key === 'previous_round') {
        category = this.$root.previousUserCategory.name
      } else {
        category = this.$root.userCategory.name
      }
      return category ? `#kategoria-${category.substring(0, 1)}` : ''
    },
    round() {
      return this.$root.state.competition[this.data.key]
    },
    roundShowResults() {
      return new Date() > new Date(this.round.end_time)
    },
  },
  mounted() {},
  methods: {},
}
</script>

<style scoped>

</style>
