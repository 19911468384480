var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('VueSelect',{class:( _obj = {
      'formulate-input-element': true
    }, _obj[("formulate-input-element--" + (_vm.context.type))] = true, _obj.valid = _vm.context.isValid, _obj.invalid = !_vm.context.isValid && _vm.context.showValidationErrors, _obj ),attrs:{"data-type":_vm.context.type,"options":_vm.dictOptions,"filterable":!_vm.search,"selectable":function (option) { return !option.disabled; }},on:{"search":_vm.onSearch,"search:blur":_vm.context.blurHandler,"input":function($event){_vm.otherOpened = arguments[0] && (arguments[0].label === _vm.otherOptionLabel)}},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
    var query = ref.search;
return [(!query)?[_vm._v(" Začnite písať pre vyhľadávanie... ")]:[_vm._v(" Žiadne možnosti nevyhovujú vyhľadávaniu. ")]]}},{key:"option",fn:function(ref){
    var optionLabel = ref.label;
return [_c('small',{class:{
          'wrapped-option': true,
          'special-option': _vm.specialPrefix && optionLabel.startsWith(_vm.specialPrefix),
        }},[_vm._v(" "+_vm._s(optionLabel)+" ")])]}},{key:"selected-option",fn:function(ref){
        var optionLabel = ref.label;
return [(!_vm.otherOpened)?_c('span',[_vm._v(" "+_vm._s(optionLabel)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.otherOptionLabel)+" ")])]}}]),model:{value:(_vm.context.model),callback:function ($$v) {_vm.$set(_vm.context, "model", $$v)},expression:"context.model"}}),(_vm.otherOpened)?_c('FormulateInput',{key:"otherText",staticClass:"p-2",attrs:{"ignored":"","help-position":"before","help":"Uveď inú odpoveď:","type":"text"},model:{value:(_vm.context.model.other),callback:function ($$v) {_vm.$set(_vm.context.model, "other", $$v)},expression:"context.model.other"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }