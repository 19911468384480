import { render, staticRenderFns } from "./CheckOrCross.vue?vue&type=template&id=030a67ad&scoped=true&"
import script from "./CheckOrCross.vue?vue&type=script&lang=js&"
export * from "./CheckOrCross.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../frontend/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "030a67ad",
  null
  
)

export default component.exports