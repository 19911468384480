import { render, staticRenderFns } from "./LoadingSpinner.vue?vue&type=template&id=304eea88&scoped=true&"
import script from "./LoadingSpinner.vue?vue&type=script&lang=js&"
export * from "./LoadingSpinner.vue?vue&type=script&lang=js&"
import style0 from "./LoadingSpinner.vue?vue&type=style&index=0&id=304eea88&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../frontend/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "304eea88",
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BSpinner} from 'bootstrap-vue'
    installComponents(component, {BSpinner})
    

export default component.exports