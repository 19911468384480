<template>
  <svg
    version="1.1"
    baseProfile="full"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-55 -55 110 110"
    :width="width"
  >
    <slot />
  </svg>
</template>

<script>
export default {
  name: 'AnimatedIcon',
  components: {},
  mixins: [],
  props: {
    size: {
      type: String,
      required: true,
      validator: value => ['sm', 'lg', 'fill'].includes(value),
    },
  },
  data() {
    return {}
  },
  computed: {
    width() {
      return {
        sm: '40px',
        lg: '120px',
        fill: '100%',
      }[this.size]
    },
  },
  mounted() {},
  methods: {},
}
</script>

<style lang="scss">

$initial-delay: 0.15s;
$circle-duration: 0.45s;
$symbol-duration: 0.3s;

.animated-circle {
  fill: none;
  stroke-width: 7;
  stroke-linecap: round;
  stroke-dasharray: 314;
  stroke-dashoffset: 314;
  stroke-opacity: 0;
  animation: $circle-duration ease-in $initial-delay forwards draw-circle;
}

@keyframes draw-circle {
  100% {
    stroke-dashoffset: 0;
    stroke-opacity: 0.5;
  }
}

.animated-path {
  fill: none;
  stroke-width: 10;
  stroke-linecap: round;
  stroke-linejoin: round;
  animation: $symbol-duration ease-out $initial-delay + $circle-duration forwards draw-path;
}

@keyframes draw-path {
  100% {
    stroke-dashoffset: 0;
  }
}

.animated-dot {
  transform-origin: 50% 50%;
  transform-box: fill-box;
  transform: scale(0);
  opacity: 0;
  animation: $symbol-duration ease-out $initial-delay+$circle-duration forwards draw-dot;
}

@keyframes draw-dot {
  30% {
    transform: scale(0);
  }
  70% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
