<template>
  <font-awesome-icon
    :icon="['fas', 'star']"
    :style="{ color }"
  />
</template>

<script>

export default {
  name: 'FavoriteStar',
  components: {},
  mixins: [],
  props: {
    color: {
      type: String,
      default: 'gold',
    },
  },
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
}
</script>

<style scoped>

</style>
