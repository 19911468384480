<template>
  <span>
    <span v-if="change">
      <font-awesome-icon
        :icon="['fas', change > 0 ? 'arrow-up' : 'arrow-down']"
        transform="shrink-3"
        :class="color ? (change > 0 ? 'text-success' : 'text-danger') : ''"
      />
      {{ Math.abs(change) }}
    </span>
    <font-awesome-icon
      v-else
      :icon="['fas', 'minus']"
      transform="shrink-3"
      :class="color ? 'text-info' : ''"
    />
  </span>
</template>

<script>
export default {
  name: 'PositionChange',
  components: {},
  mixins: [],
  props: {
    change: {
      type: Number,
      default: 0,
    },
    color: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {},
}
</script>

<style scoped>

</style>
