<template>
  <b-modal
    id="password-reset-modal"
    title="Obnova zabudnutého hesla"
    hide-footer
    centered
    size="md"
  >
    <p class="text-center">
      Ak si zabudol/-la heslo, nižšie zadaj svoj email a zašleme ti odkaz.
      Kliknutím na tento odkaz si budeš môcť obnoviť svoje heslo.
      Pozor, odkaz je platný len obmedzenú dobu.
    </p>
    <FormulateForm
      v-model="formData"
      name="password-reset"
      :schema="formSchema"
      @submit-raw="$refs.resetRecaptcha.reset()"
      @submit="requestPasswordReset"
    >
      <vue-recaptcha
        ref="resetRecaptcha"
        :load-recaptcha-script="true"
        :sitekey="recaptchaSitekey"
        class="mt-4"
        @verify="recaptchaResponse = $event; $formulate.submit('password-reset')"
      >
        <b-button
          block
          type="submit"
          variant="primary"
        >
          <LoadingSpinner
            :is-loaded="!ongoingReset"
            small
          >
            Zaslať odkaz na obnovu hesla
          </LoadingSpinner>
        </b-button>
      </vue-recaptcha>
    </FormulateForm>
  </b-modal>
</template>

<script>
import apiUsers from '../../api/users'
import constants from '../../constants'
import LoadingSpinner from '../utils/LoadingSpinner.vue'
import schema from './schema'
import VueRecaptcha from 'vue-recaptcha'

export default {
  name: 'PasswordResetModalForm',
  components: {
    LoadingSpinner,
    VueRecaptcha,
  },
  mixins: [
    apiUsers,
  ],
  props: {},
  data() {
    return {
      formSchema: schema.applyBlurHandlers(this, [
        schema.req({...schema.email, autocomplete: 'on'}, false),
      ]),
      formData: {},
      recaptchaSitekey: constants.recaptchaSitekey,
      recaptchaResponse: '',
      ongoingReset: false,
    }
  },
  computed: {},
  mounted() {},
  methods: {
    requestPasswordReset() {
      this.ongoingReset = true
      this.apiResetPassword(this.formData.email, this.recaptchaResponse).
        then(() => {
          this.$bvModal.hide('password-reset-modal')
          this.$root.successToast('Odkaz na obnovu hesla bol úspešne odoslaný!')
        }).
        catch(() => {
          this.$root.dangerToast('Chyba počas komunikácie so serverom.')
        }).
        finally(() => this.ongoingReset = false)
    },
  },
}
</script>
