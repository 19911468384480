import {
  faAngleDoubleDown, faAngleDoubleLeft, faAngleDoubleRight, faAngleDoubleUp, faAngleDown,
  faAngleLeft, faAngleRight, faAngleUp, faArrowDown, faArrowLeft, faArrowRight, faArrowUp, faBars,
  faBook, faBookOpen, faCalendarAlt, faCampground, faCaretDown, faCat, faCheck, faCheckCircle,
  faComment, faComments, faCrow, faDog, faDownload, faDragon, faEnvelope, faExclamation, faEye,
  faFileAlt, faFileContract, faFilePdf, faFileUpload, faFilm, faFish, faFrog,
  faGamepad, faGlobe, faHome, faHorse, faInfoCircle,
  faLightbulb, faMapMarker, faMapSigns, faMinus,
  faPhoneAlt, faPlus, faPrint, faQuestion, faRedo,
  faSave, faShieldAlt, faSignInAlt, faSignOutAlt,
  faSpider, faStar, faSync, faTasks, faTimes, faTools, faTrash, faTrophy, faUndo, faUpload,
  faUserCheck, faUserCircle, faUserCog, faUserFriends, faUsers, faWrench,
} from '@fortawesome/free-solid-svg-icons'
import { faGoogle, faWikipediaW } from '@fortawesome/free-brands-svg-icons'
import {
  faQuestionCircle, faComment as farComment, faEnvelope as farEnvelope,
  faLightbulb as farLightbulb,
} from '@fortawesome/free-regular-svg-icons'


export default [
  // Solid icons
  faAngleDoubleDown, faAngleDoubleLeft, faAngleDoubleRight, faAngleDoubleUp, faAngleDown,
  faAngleLeft, faAngleRight, faAngleUp, faArrowDown, faArrowLeft, faArrowRight, faArrowUp, faBars,
  faBook, faBookOpen, faCalendarAlt, faCampground, faCaretDown,
  faCat, faCheck, faCheckCircle, faComment,
  faComments, faCrow, faDog, faDownload, faDragon, faEnvelope, faExclamation, faEye, faFileAlt,
  faFileContract, faFilePdf, faFileUpload, faFish, faFrog, faGamepad, faGlobe,
  faHome, faHorse, faInfoCircle, faLightbulb,
  faMapMarker, faMapSigns, faMinus, faPhoneAlt,
  faPlus, faPrint, faQuestion, faRedo,
  faSave, faShieldAlt, faSignInAlt, faSignOutAlt, faSpider, faStar,
  faSync, faTasks, faTimes, faTools, faTrash, faTrophy, faUndo, faUpload, faUserCheck, faUserCircle,
  faUserCog, faUserFriends, faUsers, faWrench, faFilm,
  // Brands icons
  faGoogle, faWikipediaW,
  // Regular icons
  faQuestionCircle, farComment, farEnvelope, farLightbulb,
]
