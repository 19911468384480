import constants from '../constants'

export default {
  methods: {
    apiBooklets(contestId=null) {
      const query = contestId ? `?round__contest=${contestId}` : ''
      return this.$root.request({
        method: constants.httpMethods.GET,
        url: `booklets/booklets/${query}`,
        cache: { maxAge: constants.maxAgeDefaults.mid },
      })
    },
    apiBooklet(bookletId) {
      return this.$root.request({
        method: constants.httpMethods.GET,
        url: `booklets/booklets/${bookletId}/`,
        cache: { maxAge: constants.maxAgeDefaults.mid },
      })
    },
    apiUpdateBooklet(bookletId, data) {
      return this.$root.request({
        method: constants.httpMethods.PATCH,
        url: `booklets/booklets/${bookletId}/`,
        data,
      })
    },
    apiBookletAttachments(bookletId) {
      return this.$root.request({
        method: constants.httpMethods.GET,
        url: `booklets/booklets/${bookletId}/attachments/`,
        cache: { maxAge: constants.maxAgeDefaults.mid },
      })
    },
    apiHeadBookletAttachment(bookletId, attachmentId) {
      return this.$root.request({
        method: constants.httpMethods.HEAD,
        // We use ?v2 here to force chrome to "reset" its preflight cache.
        // We observed that otherwise chrome throws CORS errors, likely due to some cache,
        // but we are unsure why and when it happens (as it is also present on fresh devices).
        url: `booklets/booklets/${bookletId}/attachments/${attachmentId}/?v2`,
      }, true)
    },
    apiPostBookletAttachment(bookletId, attachment) {
      return this.$root.request({
        method: constants.httpMethods.POST,
        url: `booklets/booklets/${bookletId}/attachments/`,
        data: { attachment: attachment.file },
      })
    },
    apiDelBookletAttachment(bookletId, attachmentId){
      return this.$root.request({
        method: constants.httpMethods.DELETE,
        url: `booklets/booklets/${bookletId}/attachments/${attachmentId}/`,
      })
    },
    apiGeneratePdfBooklet(bookletId) {
      return this.$root.request({
        method: constants.httpMethods.POST,
        url: `booklets/booklets/${bookletId}/printable/`,
        data: {},
      })
    },
  },
}
